import { Button, Flex, Space, Stack, Text } from "@mantine/core";
import { LiaTeethSolid } from "react-icons/lia";
import { useNavigate } from "react-router";
import styles from "./equipment.module.css";
import { FinIcon } from "../../components/icons/fin-icon";
import { CapIcon } from "../../components/icons/caps-icon";
import { GlovesIcon } from "../../components/icons/gloves-icon";
import { SticksIcon } from "../../components/icons/sticks-icon";
import { SnorkelIcon } from "../../components/icons/snorkel-icon";
import { MaskIcon } from "../../components/icons/mask-icon";
import { LiaSocksSolid } from "react-icons/lia";

export const Equipment = () => {
  const navigate = useNavigate();

  return (
    <>
      <Flex>
        <Space h={"2rem"} />
        <Stack p="lg" w={"300"} ml={"auto"} mr={"auto"}>
          <Button
            className={styles.equipmentButton}
            size="xl"
            leftSection={<MaskIcon size={36} />}
            onClick={() => navigate("/knowledge-base/equipment/mask")}
          >
            <Text size={"xl"} className={styles.equipmentButtonText}>
              Maszk
            </Text>
          </Button>
          <Button
            className={styles.equipmentButton}
            size="xl"
            leftSection={<SnorkelIcon size={36} />}
            onClick={() => navigate("/knowledge-base/equipment/snorkel")}
          >
            <Text size={"xl"} className={styles.equipmentButtonText}>
              Pipa
            </Text>
          </Button>
          <Button
            className={styles.equipmentButton}
            size="xl"
            leftSection={<FinIcon size={36} />}
            onClick={() => navigate("/knowledge-base/equipment/fins")}
          >
            <Text size={"xl"} className={styles.equipmentButtonText}>
              Uszony
            </Text>
          </Button>
          <Button
            className={styles.equipmentButton}
            size="xl"
            leftSection={<LiaSocksSolid size={36} />}
            onClick={() => navigate("/knowledge-base/equipment/socks")}
          >
            <Text size={"xl"} className={styles.equipmentButtonText}>
              Zokni
            </Text>
          </Button>
          <Button
            className={styles.equipmentButton}
            size="xl"
            leftSection={<CapIcon size={36} />}
            onClick={() => navigate("/knowledge-base/equipment/cap")}
          >
            <Text size={"xl"} className={styles.equipmentButtonText}>
              Sapka
            </Text>
          </Button>
          <Button
            className={styles.equipmentButton}
            size="xl"
            leftSection={<LiaTeethSolid />}
            onClick={() => navigate("/knowledge-base/equipment/mouthguards")}
          >
            <Text size={"xl"} className={styles.equipmentButtonText}>
              Fogvédő
            </Text>
          </Button>
          <Button
            className={styles.equipmentButton}
            size="xl"
            leftSection={<GlovesIcon size={36} />}
            onClick={() => navigate("/knowledge-base/equipment/glove")}
          >
            <Text size={"xl"} className={styles.equipmentButtonText}>
              Kesztyű
            </Text>
          </Button>
          <Button
            className={styles.equipmentButton}
            size="xl"
            leftSection={<SticksIcon size={36} />}
            onClick={() => navigate("/knowledge-base/equipment/stick")}
          >
            <Text size={"xl"} className={styles.equipmentButtonText}>
              Ütő
            </Text>
          </Button>
        </Stack>
        {/* <List size={"xl"} spacing={"md"}>
        <List.Item icon={<TbScubaMask />}>
          <Anchor onClick={() => navigate("/knowledge-base/equipment/mask")}>
            Maszk
          </Anchor>
        </List.Item>
        <List.Item icon={<TbScubaMask />}>
          <Anchor onClick={() => navigate("/knowledge-base/equipment/snorkel")}>
            Pipa
          </Anchor>
        </List.Item>
        <List.Item icon={<GiSwimfins />}>
          <Anchor onClick={() => navigate("/knowledge-base/equipment/fins")}>
            Uszony
          </Anchor>
        </List.Item>
        <List.Item icon={<TbHelmet />}>
          <Anchor onClick={() => navigate("/knowledge-base/equipment/cap")}>
            Sapka
          </Anchor>
        </List.Item>
        <List.Item icon={<LiaTeethSolid />}>
          <Anchor
            onClick={() => navigate("/knowledge-base/equipment/mouthguards")}
          >
            Fogvédő
          </Anchor>
        </List.Item>
        <List.Item icon={<GiCookingGlove />}>
          <Anchor onClick={() => navigate("/knowledge-base/equipment/glove")}>
            Kesztyű
          </Anchor>
        </List.Item>
        <List.Item icon={<GiBat />}>
          <Anchor onClick={() => navigate("/knowledge-base/equipment/stick")}>
            Ütő
          </Anchor>
        </List.Item>
      </List> */}
      </Flex>
    </>
  );
};
