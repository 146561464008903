import {
  Anchor,
  Container,
  Grid,
  Image,
  Stack,
  Text,
  Title,
} from "@mantine/core";

export const MaskGuide = () => {
  return (
    <Container size="md" my={20}>
      <Stack p="xl">
        {/* Title */}
        <Title order={1} fw={900} ta="left">
          Vízihoki maszk vásárlási útmutató
        </Title>

        <Text size="md" ta="justify">
          A megfelelő maszk kiválasztása nagyon fontos, de szerencsére a hokis
          felszerelések közül egyértelműen ebből van a legszélesebb használható
          kínálat. A jó masz kényelmes, széles kilátást biztosít, de nem "húzza
          vissza" a játékost a víz alatt.
        </Text>

        <Text size="md" ta="justify">
          Kereséskor érdemes megnézni a magyar üzletek kínálatát, de többször
          rendeltünk már a
          <Anchor href="https://www.tradeinn.com/diveinn/en"> diveINN</Anchor>
          -ről is, ahol hatalmas választék van.{" "}
          <b>
            Mielőtt rendelsz, mindenképpen próbáld ki a csapattársak hasonló
            típusú maszkjait!
          </b>
        </Text>

        <div>
          <Title order={2} mb="md" ta="left" mt="1rem">
            Mire érdemes figyelni?
          </Title>
          <div>
            <Text ta="justify">
              <Text fw={700} span>
                {"Osztott maszk: "}
              </Text>
              mindenképp osztott maszk legyen. Ez védi kicsit az orrot és - bár
              lehet ezt frissítették az utóbbi 2 évben, mert volt róla vita -
              alapvetően a szabályok is csak ezt engedik.
            </Text>
            <Text ta="justify" mt="0.5rem">
              <Text fw={700} span>
                {"Méret: "}
              </Text>
              ne legyen túl nagy. Búvárkodásnál ugyan nem zavaró a méret, de a
              víz alatt sprintelve nagyon lehet majd érezni, ha csak egy
              kicsivel is nagyobb a maszk, plusz könnyebben le is verik
              véletlenül.
            </Text>
            <Text ta="justify" mt="0.5rem">
              <Text fw={700} span>
                {"Üvegfelület: "}
              </Text>
              ne legyen túl pici az üvegfelület rajta. Van néhány maszk, aminek
              úszószemüveg méretű, egymástól távol eső lencséje van.
            </Text>
            <Text ta="justify" mt="0.5rem">
              <Text fw={700} span>
                {"Lencse típusa: "}
              </Text>
              ne legyen hajlított a lencse. Ez torzítja a teret és az előző
              ponthoz hasonlóan emiatt nagyon megnehezíti a játékátlátást.
            </Text>
            <Text ta="justify" mt="0.5rem">
              <Text fw={700} span>
                {"Ár: "}
              </Text>
              nem kell nagyon drága, de a legolcsóbbakkal is óvatosan, mert elég
              gyenge minőségűek is vannak. Javasolt minimum 12.000 Ft körül
              keresni.
            </Text>
          </div>
        </div>

        {/* Example Masks */}
        <Title order={2} mt="xl" ta="left">
          Népszerű modellek a csapatban
        </Title>
        <Grid>
          <Grid.Col span={6}>
            <Stack>
              <Image
                src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/aqualung-sphera-x-freediving-mask.webp"
                alt="Aqualung Sphera X"
                radius="md"
                w={300}
                mx="auto"
              />
              <Text fs={"italic"} size="sm">
                Aqualung Sphera X
              </Text>
            </Stack>
          </Grid.Col>
          <Grid.Col span={6}>
            <Stack>
              <Image
                src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/mares-sealhouette-diving-mask.webp"
                alt="Mares Sealhouette"
                radius="md"
                w={300}
                mx="auto"
              />
              <Text fs={"italic"} size="sm">
                Mares Sealhouette
              </Text>
            </Stack>
          </Grid.Col>
          <Grid.Col span={6}>
            <Stack>
              <Image
                src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/x-free.webp"
                alt="X-Free"
                radius="md"
                w={300}
                mx="auto"
              />
              <Text fs={"italic"} size="sm">
                Mares X-Free
              </Text>
            </Stack>
          </Grid.Col>
          <Grid.Col span={6}>
            <Stack>
              <Image
                src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/aqualung-micromask-x-freediving-mask.webp"
                alt="Aqualung Micromask"
                radius="md"
                w={300}
              />
              <Text fs={"italic"} size="sm">
                Aqualung Micromask
              </Text>
            </Stack>
          </Grid.Col>
        </Grid>
      </Stack>
    </Container>
  );
};
