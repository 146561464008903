import { Image, Text, Container, Stack, Title, Flex } from "@mantine/core";

export const MouthguardGuide = () => {
  return (
    <Container size="md" my={20}>
      <Stack p="xl">
        <Title order={1} fw={900} ta="left">
          Fogvédő vásárlási útmutató
        </Title>

        <Stack>
          <Text size="md" ta="justify">
            Két fő típusa volt az évek során: a rácsos kialakítású régebbi
            típus, illetve az egységesebb felületű új típus.
          </Text>
          <Stack mx={"auto"}>
            <Image
              src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/regi_fogvedo.jpg"
              alt="Régi fogvédő"
              radius="md"
              w={200}
              mx="auto"
              fit="contain"
            />
            <Text fs={"italic"} size="sm">
              Régi típusú fogvédő
            </Text>
          </Stack>
        </Stack>
        <Text size="md" ta="justify">
          Mindkét típus megfelelő védelmet ad, ám a nagyobb felülete miatt a
          modern jobban ajánlott.
          <b>
            {" "}
            Fontos, hogy a modern típust szelep nélküli pipára sokkal könnyebb
            felhelyezni!
          </b>
        </Text>
        <Stack mx={"auto"}>
          <Image
            src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/modern_fogvedo.webp"
            alt="Modern fogvédő"
            radius="md"
            w={250}
            mx="auto"
          />
          <Text fs={"italic"} size="sm">
            Modernebb, új típusú fogvédő
          </Text>
        </Stack>
        <Text size="md" ta="justify">
          Az újabb típusú fogvédőből mindig tart a csapat raktáron, vásárlási
          szándék esetén keresd az edzőt!
        </Text>
      </Stack>
    </Container>
  );
};
