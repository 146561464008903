interface SticksIconProps extends React.ComponentPropsWithoutRef<"svg"> {
  size?: number | string;
}

export const SticksIcon: React.FC<SticksIconProps> = ({
  size = 150,
  style,
  ...others
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150 150"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      style={{ width: size, height: size, ...style }}
      {...others}
    >
      <g id="uuid-044c9fc2-68b9-43b0-91e4-fbcece139cf5" data-name="ütő">
        <g>
          <path d="M74.67,101.02c1.81-2.48,3.8-4.7,6.07-6.77,2.13-1.94,4.06-3.89,5.79-6.2,1.93-2.58,3.53-5.43,5.19-8.19s3.23-5.46,4.77-8.23c6.05-10.87,11.1-22.29,14.86-34.15,.76-2.4,1.39-4.99,1.02-7.52-.18-1.25-.76-2.18-1.34-3.27-.62-1.16-1.08-2.38-1.87-3.44-2.21-2.99-5.56-5.16-8.84-6.81-3.8-1.91-8.03-3.35-12.31-3.48-3.6-.11-8.01,1.02-9.75,4.53-.55,1.11-1,2.17-.91,3.44s.65,2.47,1.29,3.56,1.37,2.01,2.01,3.04c.83,1.35,1.44,2.86,1.97,4.35,2.2,6.17,2.38,12.58,1.03,18.96-1.22,5.73-3.4,11.26-5.55,16.69-1.98,5-4.26,9.92-7.62,14.16-.41,.51-.75,1.09-1.2,1.57s-1,.7-1.56,.99c-1.19,.63-2.58,.59-3.8,1.19-2.39,1.17-3.95,3.78-5.37,5.92-3.41,5.15-6.17,10.69-8.32,16.48-1.43,3.88-2.56,7.88-3.56,11.89-.86,3.46-1.58,7.02-1.7,10.6-.11,3.15,.25,6.66,2.32,9.19,2.33,2.85,6.64,3.58,9.91,2.03,2.23-1.06,3.57-2.98,3.98-5.37,.53-3.11,1.2-6.21,1.98-9.27,1.56-6.16,3.64-12.22,6.57-17.87,1.45-2.79,3.1-5.47,4.93-8.03,.94-1.31-1.23-2.56-2.16-1.26-7.04,9.79-10.93,21.38-13.23,33.12-.48,2.42-.47,4.95-2.87,6.29-1.63,.9-3.68,.91-5.37,.15-2.55-1.15-3.35-4.06-3.52-6.62-.43-6.35,1.59-12.85,3.37-18.87,1.69-5.71,3.98-11.23,6.9-16.42,1.36-2.41,2.82-4.81,4.47-7.03,.74-1,1.65-2.13,2.79-2.69,1.19-.59,2.58-.57,3.79-1.19,1-.51,1.87-1.03,2.58-1.91s1.37-1.76,2.02-2.67c1.51-2.11,2.84-4.31,3.99-6.64,2.28-4.63,4.04-9.5,5.82-14.33,2.04-5.54,3.62-11.33,3.8-17.26s-1.14-11.8-3.9-16.84c-1.05-1.92-3.3-3.93-3.32-6.25-.02-2.01,1.81-3.7,3.54-4.43,3.36-1.41,7.42-.62,10.76,.43,3.69,1.16,7.34,2.97,10.35,5.43,1.23,1,2.41,2.13,3.2,3.52,.63,1.12,1.19,2.29,1.79,3.43,.55,1.04,.58,2.21,.48,3.37-.2,2.47-1.1,4.89-1.95,7.19-.91,2.48-1.75,4.98-2.67,7.46-2.17,5.85-4.76,11.53-7.62,17.08s-6,11-9.26,16.35c-1.54,2.53-3.04,5.1-4.87,7.43-1.69,2.15-3.85,3.87-5.82,5.74s-3.66,3.98-5.26,6.16c-.95,1.3,1.22,2.55,2.16,1.26Z" />
          <path d="M59.88,98.5c-3.78,8.52-8.33,17.58-8.07,27.13,.02,.61,.96,.61,.94,0-.26-9.38,4.23-18.3,7.94-26.65,.24-.55-.57-1.03-.81-.48h0Z" />
        </g>
        <g>
          <path d="M78.64,72.01c-2.04-8.29-4.31-16.54-7.18-24.59-2.6-7.29-5.89-14.24-9.23-21.21-1.29-2.69-2.75-5.57-5.1-7.49-1.35-1.11-2.81-1.37-4.43-1.92-1.34-.46-2.63-.85-4.04-.96-4.85-.37-9.99,1.28-14.32,3.35s-8.74,5.11-10.34,9.7c-.83,2.39-.64,5.38,1.01,7.4,1.85,2.26,5.01,2.34,7.63,2.94,7.17,1.65,13.48,6.86,17.67,12.73s7.25,12.82,10.01,19.51c1.44,3.48,2.74,7.08,3.39,10.8,.12,.65,.22,1.31,.31,1.96,.11,.76,.07,1.74,.37,2.46,.53,1.25,2.34,.92,3.34,.61,2.29-.71,4.07-2.78,5.47-4.62,.89-1.17,1.56-2.51,2.36-3.74s1.49-2.41,2.22-3.64c.61-1.04,1.34-2.34,.96-3.41-.28-.81-1.44-1.32-2.09-.55-.26,.31-.42,.61-.51,1-.35,1.57,2.06,2.24,2.41,.66,.07-.22,.02-.19-.14,.11l-2.09-.55c.07,.24,.08,.14,.04-.31,0,.17-.04,.34-.1,.5-.12,.39-.35,.79-.55,1.15-.38,.67-.91,1.25-1.26,1.93-.76,1.46-1.69,2.8-2.54,4.22-.75,1.26-1.61,2.49-2.76,3.42-.45,.36-.9,.84-1.39,1.14-.3,.19-.72,.31-1.06,.38-.14,.03-.28,.07-.41,.1-.49-.11-.45-.03,.12,.23l.37,.88c-.08-2.62-.74-5.42-1.43-7.94-.83-3-1.96-5.9-3.17-8.76-2.27-5.35-4.67-10.79-7.71-15.77s-6.89-9.48-11.83-12.66c-2.59-1.67-5.44-3.07-8.38-4-2.1-.66-4.61-.54-6.53-1.7-1.8-1.09-1.99-3.65-1.43-5.49,.5-1.64,1.59-3.04,2.8-4.22,3.02-2.93,7.06-4.85,11.05-6.08,3.64-1.12,7.54-1.85,11.23-.6,1.21,.41,2.69,.61,3.74,1.34s1.88,1.78,2.58,2.84c1.34,2.03,2.34,4.26,3.37,6.45,1.51,3.23,3.13,6.4,4.54,9.68,1.52,3.55,2.89,7.18,4.15,10.83,2.54,7.4,4.61,14.96,6.47,22.55,.38,1.56,2.79,.9,2.41-.66h0Z" />
          <path d="M66.03,111.66c2.26,5.85,5.19,11.37,8.45,16.71,2.57,4.21,5.53,8.65,9.68,11.47,1.74,1.19,3.86,2.04,6.01,1.84,2.48-.22,4.8-1.68,6.2-3.72,1.9-2.78,1.69-6.04-.21-8.7-2.28-3.2-4.41-6.52-6.37-9.93-4.58-8-7.99-16.62-9.29-25.78-.22-1.59-2.63-.91-2.41,.66,1.05,7.41,3.48,14.52,6.79,21.22,1.59,3.22,3.38,6.35,5.3,9.38,.87,1.38,1.77,2.74,2.69,4.08s2.28,2.71,2.37,4.46c.13,2.55-1.79,4.86-4.16,5.62-1.74,.55-3.5,.02-5.01-.89-3.81-2.29-6.53-6.38-8.84-10.06-3.4-5.41-6.47-11.07-8.77-17.04-.57-1.49-2.99-.84-2.41,.66h0Z" />
        </g>
      </g>
      <g id="uuid-0c71bd4f-39c3-4ca8-9fb8-86361faed686" data-name="satír">
        <path d="M36.01,19.73c-.15,.63-.7,1.3-1.05,1.85-.42,.66-.78,1.36-1.22,2.01-.89,1.32-1.71,2.73-2.45,4.14s-1.48,2.7-2.17,4.08c-.32,.65-.78,1.29-1.22,1.86-.36,.46-.62,.91-.59,1.51s.98,.61,.94,0c-.04-.64,.6-1.15,.91-1.67,.37-.61,.72-1.2,1.06-1.83,.75-1.35,1.43-2.79,2.26-4.09,.42-.66,.76-1.35,1.12-2.03s.83-1.25,1.21-1.9,.77-1.28,1.15-1.92c.33-.56,.79-1.11,.95-1.75,.14-.59-.77-.84-.91-.25h0Z" />
        <path d="M42.94,17.82c-.44,.55-.64,1.16-.89,1.81-.19,.49-.41,.96-.63,1.43-.2,.41-.53,.71-.73,1.1-.15,.29-.14,.63-.28,.92-.18,.39-.46,.77-.71,1.12-.51,.69-.88,1.45-1.38,2.15-1.15,1.63-2.03,3.36-3.01,5.09-.41,.71-.76,1.5-1.22,2.18-.24,.34-.58,.61-.79,.98-.05,.09-.23,.65-.19,.64-.55,.23-.31,1.14,.25,.91,.69-.29,.55-.85,.98-1.38,.49-.6,.84-1.23,1.25-1.89,1.06-1.72,1.85-3.57,2.99-5.25,.53-.78,.98-1.62,1.51-2.39,.44-.65,1-1.24,1.29-1.99,.3-.79,.77-1.49,1.13-2.28s.56-1.8,1.1-2.47c.38-.47-.29-1.14-.67-.67h0Z" />
        <path d="M48.5,17.07c-.24,.38-.37,.71-.51,1.12-.17,.51-.44,.83-.76,1.27-.66,.92-1.16,1.99-1.76,2.96s-1.23,1.97-1.92,2.92c-.3,.41-.49,.87-.75,1.3-.2,.33-.5,.56-.67,.9-1.11,2.18-2.3,4.34-3.47,6.5-.55,1.01-1.19,1.96-1.72,2.98-.39,.74-.93,1.6-1.04,2.43h.91v-.1c.02-.58-.88-.58-.9,0v.1c-.03,.6,.83,.56,.9,0,.04-.32,.35-.69,.49-1,.24-.55,.46-1.11,.78-1.62,.59-.94,1.11-1.88,1.67-2.84,1.16-2.01,2.15-4.15,3.32-6.12,1.03-1.74,2.25-3.38,3.29-5.13,.59-.99,1.13-2.07,1.82-3,.53-.71,.67-1.46,1.12-2.19,.31-.5-.48-.95-.78-.46h0Z" />
        <path d="M52.74,18.25c-.53,.78-1.03,1.56-1.56,2.36-.6,.91-1.03,1.85-1.54,2.81-.44,.82-1.06,1.56-1.61,2.31-.67,.91-1.19,1.94-1.81,2.88-.53,.79-1.04,1.57-1.52,2.37-.57,.95-.88,1.91-1.62,2.77-.67,.78-1.32,1.57-1.91,2.42-.25,.36-.47,.74-.64,1.15s-.28,1.6-.84,1.64c-.6,.05-.61,.99,0,.94,.76-.06,1.21-.64,1.45-1.33,.4-1.17,.9-2.03,1.66-3.02,.69-.92,1.53-1.71,2.07-2.74,.47-.89,.95-1.73,1.42-2.61,.62-1.15,1.44-2.22,2.13-3.33,.55-.89,1.22-1.76,1.83-2.61s.93-1.98,1.54-2.89,1.16-1.76,1.75-2.64c.34-.5-.48-.98-.81-.48h0Z" />
        <path d="M56.82,19.78c-1.82,4.17-4.88,7.68-7.18,11.59-.25,.43-.64,.73-.89,1.15s-.37,.87-.58,1.28c-.48,.91-1.32,1.69-1.9,2.53-.26,.37-.38,.81-.57,1.22-.27,.57-.67,1.03-1.03,1.54-.62,.86-1.23,1.7-1.81,2.59l.88,.24v-.1c.06-.6-.88-.6-.93,0v.1c-.06,.5,.63,.59,.87,.24,.55-.83,1.14-1.62,1.71-2.44,.34-.49,.75-.92,1.03-1.45s.4-1.11,.77-1.57c.59-.73,1.29-1.45,1.74-2.28,.23-.41,.35-.88,.58-1.29,.26-.47,.69-.79,.96-1.27,2.22-3.98,5.34-7.39,7.18-11.59,.24-.55-.57-1.03-.81-.48h0Z" />
        <path d="M60.13,24.11c-.64,.6-.89,1.4-1.33,2.14-.55,.91-1.14,1.88-1.77,2.73-1.18,1.58-2.12,3.27-3.18,4.92-1.15,1.78-2.09,3.68-3.38,5.36-.66,.85-1.12,1.81-1.81,2.64-.58,.69-1.01,1.49-1.52,2.23-.35,.5,.47,.97,.81,.48,.56-.81,1.06-1.63,1.68-2.4s1-1.62,1.58-2.37c1.25-1.64,2.18-3.45,3.29-5.19,1.02-1.61,1.95-3.26,3.07-4.8,.58-.8,1.07-1.64,1.63-2.45s.88-1.92,1.61-2.6c.44-.42-.22-1.08-.67-.67h0Z" />
        <path d="M61.32,29.21c-.05-.05-2.31,3.2-2.44,3.42-.5,.83-1.15,1.53-1.73,2.31-.62,.83-1.12,1.74-1.66,2.62s-1.19,1.67-1.71,2.55-.98,1.82-1.6,2.66c-.55,.76-1.29,1.39-1.77,2.19-.25,.42-.38,.83-.67,1.23-.27,.37-.33,.39-.67,.64l.69,.53,.1-.32c.18-.58-.74-.83-.91-.25l-.1,.32c-.11,.37,.35,.78,.69,.53,.24-.17,.47-.24,.68-.46,.28-.29,.56-.78,.73-1.14,.47-.95,1.11-1.59,1.75-2.42,.81-1.05,1.33-2.23,2.06-3.32,.66-.99,1.34-1.93,1.95-2.96s1.32-2.02,2.09-2.98c.67-.84,1.19-1.79,1.81-2.67,.47-.67,2.24-2.31,1.37-3.17-.43-.43-1.1,.24-.67,.67h0Z" />
        <path d="M64.42,33.92l.1,.03-.33-.58c-.16,.38-.55,.7-.74,1.08-.23,.47-.39,.83-.71,1.24-.51,.65-.97,1.34-1.44,2.03-1.21,1.78-2.47,3.54-3.89,5.17-1.19,1.36-2.38,2.71-3.38,4.2-.88,1.3-2.13,2.37-2.58,3.94l.58-.33-.11-.02,.33,.33-.02-.14c-.08-.6-.99-.34-.91,.25l.02,.14c.02,.15,.19,.3,.33,.33l.11,.02c.25,.05,.51-.07,.58-.33,.44-1.56,1.84-2.69,2.67-4.04,1-1.63,2.54-2.97,3.72-4.48,1.27-1.62,2.48-3.25,3.64-4.96,.5-.73,1.12-1.42,1.56-2.18,.21-.35,.31-.75,.54-1.08s.45-.54,.61-.92c.1-.24-.11-.52-.33-.58l-.1-.03c-.59-.16-.84,.75-.25,.91h0Z" />
        <path d="M65.28,37.26c-.15,.69-.81,1.23-1.22,1.79-.49,.66-.91,1.36-1.36,2.05-.41,.63-.89,1.21-1.32,1.83s-.8,1.27-1.32,1.85c-1.04,1.16-1.81,2.48-2.75,3.71-.48,.64-.96,1.28-1.42,1.93s-.85,1.47-1.37,2.12c-.37,.47,.29,1.14,.67,.67,.47-.59,.76-1.27,1.19-1.88,.52-.73,1.04-1.45,1.59-2.16,.5-.65,.94-1.33,1.4-2.01,.52-.76,1.12-1.41,1.7-2.12,.47-.58,.83-1.2,1.26-1.81s.86-1.16,1.25-1.76c.51-.79,1-1.62,1.61-2.35,.43-.51,.87-.94,1.01-1.61,.13-.59-.78-.85-.91-.25h0Z" />
        <path d="M67.54,39.87c-.37,.8-.94,1.45-1.47,2.14-.61,.8-1.16,1.64-1.74,2.46-1,1.42-2.15,2.71-3.15,4.14-.45,.65-.96,1.23-1.41,1.89-.53,.79-1.11,1.59-1.76,2.28-.62,.66-1.15,1.37-1.72,2.06-.24,.29-.44,.59-.64,.91-.14,.22-.3,.69-.54,.81-.55,.26-.07,1.07,.48,.81,.5-.24,.72-.92,1.01-1.36,.52-.78,1.16-1.57,1.78-2.26,1.2-1.33,2.18-2.81,3.28-4.22s2.2-2.86,3.28-4.3c.61-.81,1.14-1.67,1.75-2.48s1.26-1.51,1.67-2.4c.25-.55-.56-1.03-.81-.48h0Z" />
        <path d="M69.25,43.78c-.48,.67-.89,1.37-1.44,1.98s-1.05,1.23-1.55,1.88c-1.07,1.39-2.19,2.75-3.15,4.21-.39,.59-.91,1.12-1.34,1.69-.45,.61-.85,1.19-1.24,1.84s-1,1.15-1.46,1.76c-.43,.57-.87,1.14-1.32,1.69-.38,.47,.28,1.14,.67,.67,.42-.51,.83-1.02,1.23-1.55,.44-.6,.99-1.1,1.44-1.7,.47-.64,.83-1.35,1.32-1.98,.45-.58,.96-1.12,1.38-1.72,2.03-2.82,4.29-5.46,6.29-8.29,.35-.5-.47-.97-.81-.48h0Z" />
        <path d="M70.27,48.38c-.7,1.47-1.76,2.79-2.76,4.09-1.21,1.56-2.49,3.08-3.71,4.63-.49,.62-.86,1.27-1.45,1.78-.51,.44-1.04,1.15-1.41,1.7-.15,.24-.2,.48-.4,.67-.23,.22-.41,.34-.61,.62-.25,.34-.51,.66-.7,1.03-.03,.07-.3,.73-.29,.73-.59,.08-.6,1.02,0,.94s.76-.46,.99-.95c.18-.4,.43-.72,.68-1.07,.23-.32,.55-.49,.79-.79,.22-.27,.32-.62,.54-.9,.26-.33,.51-.69,.78-1.01,.24-.28,.57-.44,.82-.72,.3-.34,.48-.79,.76-1.15,1.2-1.55,2.48-3.02,3.67-4.57,1.12-1.45,2.33-2.9,3.12-4.56,.26-.55-.55-1.02-.81-.48h0Z" />
        <path d="M71.91,51.68c0,.78-.84,1.66-1.29,2.27-.51,.7-1.01,1.54-1.61,2.16-.46,.47-.79,1.01-1.27,1.46-.61,.56-.93,1.34-1.47,1.95s-1.17,1.18-1.69,1.84c-.54,.69-.99,1.42-1.56,2.08-.25,.28-.45,.57-.64,.9-.23,.41-.51,.7-.81,1.06-.22,.26-.35,.57-.52,.86-.26,.42-.59,.76-.82,1.2-.28,.54,.53,1.01,.81,.48,.21-.4,.51-.71,.76-1.08,.29-.44,.5-.83,.86-1.22,.26-.29,.41-.58,.6-.91,.25-.44,.61-.77,.93-1.16,.56-.68,.97-1.46,1.57-2.11s1.24-1.23,1.73-1.95c.25-.36,.44-.78,.75-1.1,.21-.22,.47-.39,.66-.63,.27-.33,.47-.68,.77-.98,.26-.26,.47-.52,.67-.83,.49-.77,1.08-1.47,1.58-2.23,.46-.69,.93-1.18,.93-2.04,0-.61-.95-.61-.94,0h0Z" />
        <path d="M73.24,55.94c-.24,.25-.32,.5-.49,.78-.2,.33-.46,.62-.67,.95-.47,.75-.95,1.49-1.48,2.19-1.04,1.36-2.15,2.72-3.31,3.98-.98,1.05-1.97,2.01-2.76,3.22-.18,.28-.41,.46-.63,.7-.24,.26-.38,.59-.61,.86-.5,.62-1.06,1.18-1.64,1.72l.79,.46,.02-.11c.12-.59-.79-.85-.91-.25l-.02,.11c-.09,.46,.43,.79,.79,.46,.58-.55,1.12-1.11,1.64-1.72,.2-.23,.33-.5,.52-.74s.43-.43,.62-.66c.45-.55,.78-1.18,1.26-1.72,.51-.57,1.08-1.1,1.6-1.66,1.12-1.2,2.17-2.52,3.18-3.81,.53-.67,1.01-1.37,1.47-2.09,.22-.35,.43-.7,.67-1.04,.22-.3,.38-.71,.63-.97,.43-.43-.24-1.1-.67-.67h0Z" />
        <path d="M74.52,60.97c-.87,1.24-1.88,2.36-2.76,3.59-.49,.68-.92,1.4-1.49,2.01-.51,.55-1.07,1.05-1.59,1.6s-1.03,1.04-1.51,1.59-.81,1.18-1.29,1.71c-.46,.5-.88,1.01-1.29,1.56-.23,.31-.51,.53-.77,.81-.17,.19-.29,.55-.49,.69l.69,.28-.03-.11c-.17-.58-1.08-.33-.91,.25l.03,.11c.08,.27,.43,.46,.69,.28,.21-.15,.33-.29,.47-.51,.19-.3,.4-.49,.65-.74,.54-.54,.93-1.22,1.46-1.78,.49-.52,.86-1.09,1.29-1.66,.49-.65,1.12-1.22,1.68-1.81,.51-.55,1.08-1.05,1.59-1.6,.54-.58,.96-1.24,1.41-1.88,.95-1.34,2.04-2.56,2.99-3.91,.35-.5-.47-.97-.81-.48h0Z" />
        <path d="M75.96,65.82c-1.04,.84-1.95,2-2.78,3.04-.94,1.18-1.79,2.46-2.8,3.58-.48,.53-1.01,1.01-1.46,1.56s-.92,1.12-1.42,1.64-.86,1.13-1.33,1.67c-.23,.26-.5,.49-.73,.76-.2,.24-.34,.53-.59,.72l.69,.28-.03-.11c-.15-.59-1.06-.34-.91,.25l.03,.11c.07,.27,.45,.47,.69,.28,.48-.36,.79-.89,1.22-1.32,.52-.53,.91-1.17,1.4-1.73,.96-1.12,1.94-2.22,2.96-3.29s1.85-2.32,2.75-3.49c.47-.61,1-1.16,1.49-1.74,.47-.55,.93-1.1,1.49-1.56,.47-.38-.2-1.05-.67-.67h0Z" />
        <path d="M76.64,70.41c-.4,.42-.8,.89-1.15,1.35-.41,.55-.91,1.02-1.38,1.52-.95,1.02-1.97,1.94-2.92,2.96s-2.03,2.04-3.07,3.01c-.48,.45-.95,.91-1.41,1.39-.4,.41-.81,.79-.92,1.38s.79,.85,.91,.25c.11-.54,.66-.94,1.02-1.34,.45-.49,.96-.9,1.43-1.36,1-.98,2.03-1.92,2.97-2.95,.45-.5,.94-.92,1.44-1.37,.47-.42,.86-.91,1.28-1.37s.88-.87,1.26-1.37,.78-.99,1.2-1.43-.25-1.11-.67-.67h0Z" />
        <path d="M79.4,99.51c0,.08-.31,.25-.39,.3-.18,.13-.34,.26-.49,.42-.38,.42-.73,.83-1.16,1.21s-.84,.81-1.31,1.17c-.44,.34-.89,.66-1.31,1.02s-.82,.61-1.16,1.04c-.36,.47-.82,.89-1.26,1.29-.39,.35-.78,.69-1.11,1.09-.19,.23-.36,.5-.59,.69-.2,.16-.42,.29-.62,.45-.47,.39,.2,1.05,.67,.67,.22-.18,.48-.31,.69-.51,.2-.19,.35-.43,.52-.64,.38-.45,.81-.81,1.24-1.21s.87-.83,1.24-1.3c.23-.29,.49-.45,.78-.68,.23-.18,.44-.39,.67-.57,.95-.75,1.95-1.47,2.78-2.36,.35-.37,.62-.78,1.04-1.09,.37-.27,.66-.54,.71-1.02,.06-.6-.88-.6-.94,0h0Z" />
        <path d="M80.55,104.11c-.52,.52-1.02,1.07-1.58,1.56-.24,.21-.52,.39-.74,.62s-.35,.51-.57,.73-.51,.37-.75,.57c-.27,.23-.51,.5-.77,.75-.49,.49-1.08,.86-1.59,1.33-.45,.41-.92,.79-1.39,1.18-.55,.46-1.04,1-1.55,1.51-.45,.45-.88,.93-1.36,1.35-.36,.31-.82,.6-1.01,1.05-.23,.55,.58,1.03,.81,.48,.17-.4,.64-.65,.95-.94,.49-.45,.95-.95,1.42-1.42,.94-.94,1.91-1.82,2.93-2.67,.52-.43,1.05-.8,1.52-1.28,.26-.27,.52-.54,.82-.77,.23-.17,.48-.33,.67-.54s.32-.46,.51-.67c.22-.23,.5-.41,.74-.62,.55-.49,1.06-1.04,1.58-1.56,.43-.43-.24-1.1-.67-.67h0Z" />
        <path d="M82.6,107.69c-.99,1-1.96,2.04-3.08,2.9-.51,.39-1.04,.74-1.5,1.18-.5,.47-.93,1.01-1.45,1.45-.5,.42-1.03,.79-1.53,1.21-.46,.37-1.01,.76-1.41,1.19s-.76,1.01-1.24,1.38c-.21,.16-.43,.3-.62,.48-.21,.21-.37,.46-.63,.63l.69,.28-.03-.11c-.16-.59-1.07-.34-.91,.25l.03,.11c.08,.28,.44,.45,.69,.28,.46-.3,.78-.77,1.23-1.09,.49-.35,.87-.86,1.26-1.32s.91-.82,1.37-1.23c.51-.45,1.07-.85,1.6-1.27s.93-.9,1.39-1.37c.51-.52,1.11-.92,1.69-1.36,1.13-.87,2.11-1.92,3.11-2.92,.43-.43-.24-1.1-.67-.67h0Z" />
        <path d="M83.83,111.82c-.49,.18-.77,.61-1.14,.95-.49,.45-.99,.89-1.45,1.37-.44,.45-.87,.9-1.34,1.31s-.98,.81-1.41,1.28-.77,.97-1.15,1.46-.86,.94-1.33,1.38-.89,.9-1.32,1.36c-.4,.43-.86,1.05-1.42,1.28s-.31,1.13,.25,.91c1.09-.43,1.83-1.55,2.62-2.36,.43-.44,.89-.84,1.31-1.28s.82-.97,1.21-1.48c.79-1.01,1.81-1.73,2.7-2.64,.45-.46,.89-.92,1.36-1.36,.25-.23,.52-.46,.76-.69,.17-.16,.36-.5,.59-.58,.57-.21,.32-1.12-.25-.91h0Z" />
        <path d="M86.27,115.1c-.44,.2-.66,.67-.94,1.04-.39,.5-.97,.88-1.41,1.34-.48,.49-.95,.99-1.43,1.48-.5,.51-1.12,.91-1.65,1.4-1,.94-1.97,1.9-3.03,2.77-.56,.47-1.07,.97-1.58,1.48-.24,.24-.49,.48-.73,.73-.21,.23-.39,.56-.74,.59-.6,.04-.61,.99,0,.94s.92-.31,1.3-.74c.5-.56,1.05-1.09,1.59-1.61,1.05-1.02,2.23-1.89,3.27-2.93,.54-.54,1.09-1.03,1.69-1.5s1.04-1.02,1.56-1.53c.48-.48,.96-.94,1.47-1.39,.38-.34,.66-1.06,1.11-1.26,.55-.25,.07-1.06-.48-.81h0Z" />
        <path d="M87.46,118.85c-.28,.52-.61,1.09-1.08,1.46s-.77,.84-1.18,1.28c-.9,.96-1.93,1.8-2.88,2.71s-2.02,1.66-2.97,2.56c-.48,.45-.91,.93-1.35,1.42-.18,.2-.37,.38-.56,.57-.17,.16-.33,.46-.54,.55-.55,.26-.07,1.07,.48,.81,.22-.1,.33-.26,.48-.44,.18-.21,.4-.38,.59-.58,.47-.48,.88-1,1.36-1.47,1.01-.98,2.16-1.79,3.18-2.76s2.1-1.82,3.01-2.85c.19-.22,.35-.47,.54-.69s.43-.36,.64-.54c.46-.39,.81-1.02,1.1-1.55s-.52-1.01-.81-.48h0Z" />
        <path d="M88.94,122.53c-.13,.32-.65,.58-.9,.81-.46,.43-.87,.89-1.36,1.3-1.02,.84-1.85,1.78-2.71,2.77-.4,.46-.88,.85-1.29,1.32s-.76,.96-1.23,1.37-.96,.71-1.35,1.16c-.3,.35-.81,.54-1,.98l.86,.36,.02-.11c.09-.59-.82-.85-.91-.25l-.02,.11c-.07,.47,.63,.9,.86,.36,.1-.23,.37-.29,.55-.45s.31-.37,.48-.54c.46-.45,1.02-.78,1.47-1.23s.78-.96,1.21-1.4c.45-.47,.92-.9,1.33-1.41,.83-1.03,1.81-1.83,2.79-2.71,.42-.37,.77-.81,1.2-1.17,.36-.3,.72-.58,.91-1.02,.23-.56-.68-.8-.91-.25h0Z" />
        <path d="M91.51,125.45l.15-.11-.69-.53c-.05,.23-.31,.4-.48,.55-.21,.19-.41,.39-.6,.6-.41,.45-.8,.91-1.15,1.4-.74,1.05-1.64,2-2.51,2.96-.43,.48-.89,.93-1.32,1.41s-.77,.98-1.22,1.43c-.3,.3-.58,.59-.84,.92-.11,.15-.23,.29-.35,.43-.06,.07-.16,.14-.21,.22-.07,.1-.14,.03,0,.04l.45,.35-.03-.15c-.11-.6-1.02-.34-.91,.25l.03,.15c.04,.21,.26,.33,.45,.35,.7,.06,1.18-.97,1.58-1.4,.48-.53,.97-1.01,1.42-1.57s.99-1.09,1.49-1.63c.95-1.03,1.93-2.07,2.73-3.21,.4-.57,.87-1.12,1.37-1.61,.37-.37,.89-.68,1.01-1.23,.08-.36-.34-.79-.69-.53l-.15,.11c-.49,.35-.02,1.17,.48,.81h0Z" />
        <path d="M93.15,127.94c-.36,.32-.49,.79-.79,1.15-.36,.43-.76,.82-1.1,1.27-.77,1.01-1.55,1.99-2.41,2.92-.81,.88-1.55,1.82-2.33,2.72-.33,.38-.63,.76-1,1.11-.32,.31-.59,.64-.67,1.09-.1,.59,.81,.85,.91,.25,.07-.43,.42-.64,.7-.93,.36-.37,.68-.8,1.02-1.19,.77-.88,1.49-1.79,2.29-2.65s1.45-1.72,2.14-2.62c.35-.46,.74-.86,1.12-1.3,.3-.35,.45-.85,.79-1.15,.46-.4-.21-1.07-.67-.67h0Z" />
        <path d="M95.19,131.69c-.74,.76-1.32,1.64-2.02,2.44s-1.45,1.46-2.25,2.14c-.72,.62-1.52,1.32-2,2.15-.25,.42-.39,.8-.76,1.14s-.74,.75-1.11,1.11l.79,.21-.03-.12c-.15-.59-1.06-.34-.91,.25l.03,.12c.09,.35,.54,.45,.79,.21,.7-.69,1.45-1.32,1.89-2.2s1.25-1.57,1.98-2.19c.79-.68,1.56-1.35,2.25-2.14s1.28-1.68,2.02-2.44c.42-.43-.24-1.1-.67-.67h0Z" />
      </g>
    </svg>
  );
};
