import {
  Image,
  Text,
  Flex,
  Anchor,
  Container,
  Stack,
  Title,
} from "@mantine/core";

export const GlovesGuide = () => {
  return (
    <Container size="md" my={20}>
      <Stack p="xl">
        {/* Title */}
        <Title order={1} fw={900} ta="left">
          Kesztyű vásárlási útmutató
        </Title>

        <Text size="md" ta="justify">
          A jó kesztyű egyszerre védi a kezet a korongtól és ütközésektől úgy,
          hogy mellette a lehető legkevésbé korlátozza a kéz mozgását. Bár egy
          vastagabb védőréteggel rendelkező kesztyű sokkal jobban óvja a kezet,
          erősen tudja a cselezést és lövést hátráltatni.
        </Text>
        <Text size="md" ta="justify">
          A kesztyűt vehetjük boltban vagy készíthetjük magunk is. Előbbi
          esetben biztosabbak lehetünk a minőségben és a méretben, utóbbi
          esetben viszont jobban a saját igényeinkre alakíthatjuk a kesztyűt,
          valamint sokkal olcsóbban (kb. tized annyiból) megúszhatjuk a dolgot.
        </Text>

        <Title order={2} fw={900} ta="left" mt="1.5rem">
          Boltban vehető modellek
        </Title>
        <Text size="md" ta="justify">
          Jelenleg egyértelműen a HydroUWH cég termékei képviselik a csúcsát a
          boltban kapható kesztyűknek, nagyon mást nem is ajánlanék jó szívvel.
          Ezek meglehetősen drágák (típustól függően akár 30-35000 HUF), de
          cserébe évekig remek teljesítménnyel szolgálnak.
        </Text>
        <Stack>
          <Text size="md" ta="justify">
            A{" "}
            <b>
              <Anchor href="https://hydrouwh.com/collections/gloves/products/t2">
                {"T2"}
              </Anchor>
            </b>{" "}
            és a{" "}
            <b>
              <Anchor href="https://hydrouwh.com/collections/gloves/products/skelly-glove">
                {"Skelly "}
              </Anchor>
            </b>
            modellek hasonló tulajdonságokkal rendelkeznek: vastag szilikon
            réteg, amely nagyon jó védelmet nyújt, cserébe egy kicsit gátolva
            egy-egy (főleg invertált kezes) csel végrehajtását. Mindkét típust a
            sport legmagasabb szintjén is használják, így biztosak lehetünk
            benne, hogy nem fogunk mellé egyikkel sem.
          </Text>
          <Flex>
            <Stack ml={"auto"}>
              <Image
                src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/skelly.webp"
                alt="HydroUWH Skelly"
                radius="md"
                w={200}
                mx="auto"
              />
              <Text fs={"italic"} size="sm">
                HydroUWH Skelly
              </Text>
            </Stack>
            <Stack mr={"auto"} ml={"5rem"}>
              <Image
                src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/t2.webp"
                alt="HydroUWH T2"
                radius="md"
                w={200}
                mx="auto"
              />
              <Text fs={"italic"} size="sm">
                HydroUWH T2
              </Text>
            </Stack>
          </Flex>
        </Stack>
        <Stack>
          <Text size="md" ta="justify">
            A{" "}
            <b>
              <Anchor href="https://hydrouwh.com/collections/gloves/products/ninja">
                {"Ninja"}
              </Anchor>
            </b>{" "}
            modell egy kicsit keményebb fajta polimerből készült, ezáltal
            ugyanazt a védelmet tudja nyújtani vékonyabb védőréteg
            kialakításával. Emiatt a cselezés és lövés is könnyebb vele, ám az
            ára is 20-30% több, mint a fentebb említett típusoknak.
          </Text>
          <Stack>
            <Image
              src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/ninja.jpg"
              alt="HydroUWH Ninja"
              radius="md"
              w={200}
              mx="auto"
            />
            <Text fs={"italic"} size="sm">
              HydroUWH Ninja
            </Text>
          </Stack>
        </Stack>
        <Title order={2} fw={900} ta="left" mt="1.5rem">
          Otthon készített kesztyű
        </Title>
        <Text size="md" ta="justify">
          Egy szövetkesztyű, szilikon és egy kis akril festék segítségével elég
          jó kesztyűt lehet akár otthon is készíteni. A csapat próbál igény
          szerint évente többször akár kesztyűkészítést tartani, ahol bárki
          készíthet magának saját kesztyűt.
        </Text>
        <Text size="md" ta="justify">
          Hacsak nem magabiztos benne valaki, hogy szüksége van a bolti
          kesztyűre, akkor mindenképpen a saját készítésűt ajánlom.
        </Text>
      </Stack>
    </Container>
  );
};
