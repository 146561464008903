import { createTheme, MantineColorsTuple } from "@mantine/core";

const myblue: MantineColorsTuple = [
  "#BED3EB",
  "#6B9ED9",
  "#2B76CD",
  "#1A5AA3",
  "#0E4482",
  "#063369",
  "#002754",
  "#031B37",
  "#041324",
  "#040D18",
];

const mygray: MantineColorsTuple = [
  "#D9DBE0",
  "#B0B4BF",
  "#8D94A5",
  "#6F7890",
  "#5A6172",
  "#494E5A",
  "#3B3E47",
  "#303239",
  "#27282D",
  "#1F2024",
];

// const myyellow: MantineColorsTuple = [
//   "#F6F6C3",
//   "#F1F17E",
//   "#F5F53D",
//   "#FFFF00",
//   "#C2C20A",
//   "#949410",
//   "#717112",
//   "#565612",
//   "#424211",
//   "#333310",
// ];

const myyellow: MantineColorsTuple = [
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF",
];

export const theme = createTheme({
  colors: {
    myblue,
    mygray,
    myyellow,
  },
  primaryColor: "myblue",
  // You can add more theme customizations here
});
