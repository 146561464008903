interface StravaIconProps extends React.ComponentPropsWithoutRef<"svg"> {
  size?: number | string;
}

export const StravaIcon: React.FC<StravaIconProps> = ({
  size = 150,
  style,
  ...others
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 75 150"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      style={{ width: size, height: size, ...style }}
      {...others}
    >
      <title>api_logo</title>
      <path
        className="cls-1"
        d="M14.62,34.12a14.15,14.15,0,0,1-4.31-.64,9.54,9.54,0,0,1-3.44-1.91l2.7-3.21a8,8,0,0,0,2.59,1.36,9.31,9.31,0,0,0,2.7.41,2.13,2.13,0,0,0,1-.17,0.53,0.53,0,0,0,.3-0.47v0a0.63,0.63,0,0,0-.44-0.54,7.69,7.69,0,0,0-1.65-.45q-1.27-.26-2.43-0.61a8.35,8.35,0,0,1-2-.88A4.27,4.27,0,0,1,8.21,25.6a3.69,3.69,0,0,1-.52-2v0a4.78,4.78,0,0,1,.42-2,4.57,4.57,0,0,1,1.23-1.62,5.85,5.85,0,0,1,2-1.08,8.9,8.9,0,0,1,2.75-.39A12.87,12.87,0,0,1,18,19a9.18,9.18,0,0,1,3,1.55l-2.46,3.41a7.57,7.57,0,0,0-2.28-1.13,7.93,7.93,0,0,0-2.26-.36,1.56,1.56,0,0,0-.83.17,0.51,0.51,0,0,0-.27.45v0a0.62,0.62,0,0,0,.41.52,7,7,0,0,0,1.6.45,22.37,22.37,0,0,1,2.64.62,7.8,7.8,0,0,1,2,.94A4.16,4.16,0,0,1,20.83,27,3.81,3.81,0,0,1,21.29,29v0a4.69,4.69,0,0,1-.48,2.14,4.57,4.57,0,0,1-1.34,1.61,6.35,6.35,0,0,1-2.09,1A9.87,9.87,0,0,1,14.62,34.12Z"
      />
      <path
        className="cls-1"
        d="M25.46,23H21V18.74H35V23H30.51V33.84H25.46V23Z"
      />
      <path
        className="cls-1"
        d="M35.67,18.74H43a10.1,10.1,0,0,1,3.33.46,5.54,5.54,0,0,1,2.1,1.26,4.61,4.61,0,0,1,1,1.55,5.48,5.48,0,0,1,.35,2v0a4.77,4.77,0,0,1-.8,2.8,5.5,5.5,0,0,1-2.18,1.81l3.52,5.14H44.64l-2.85-4.32H40.72v4.32H35.67V18.74Zm7.23,7.19a2.32,2.32,0,0,0,1.42-.39,1.28,1.28,0,0,0,.52-1.08v0a1.23,1.23,0,0,0-.52-1.09,2.44,2.44,0,0,0-1.4-.36h-2.2v3H42.9Z"
      />
      <polygon
        className="cls-1"
        points="79.61 27.34 82.91 33.84 87.75 33.84 79.61 17.79 71.48 33.84 76.32 33.84 79.61 27.34"
      />
      <polygon
        className="cls-1"
        points="56.98 27.34 60.27 33.84 65.11 33.84 56.98 17.79 48.85 33.84 53.69 33.84 56.98 27.34"
      />
      <polygon
        className="cls-1"
        points="68.3 25.23 65.01 18.74 60.17 18.74 68.3 34.79 76.43 18.74 71.59 18.74 68.3 25.23"
      />
      <path
        className="cls-1"
        d="M7.55,13.15V7.55H9.79A2,2,0,0,1,11.17,8a1.65,1.65,0,0,1,.53,1.3,1.65,1.65,0,0,1-.51,1.28,2,2,0,0,1-1.39.47H8.5v2.08H7.55Zm0.94-3H9.77A1,1,0,0,0,10.46,10a0.81,0.81,0,0,0,.27-0.64,0.81,0.81,0,0,0-.28-0.68,1.1,1.1,0,0,0-.69-0.22H8.5V10.2Z"
      />
      <path
        className="cls-1"
        d="M16,13.11a2.51,2.51,0,0,1-1.8,0,2.23,2.23,0,0,1-1.32-1.44,4.25,4.25,0,0,1-.2-1.36,3,3,0,0,1,.71-2.12,2.26,2.26,0,0,1,1.7-.75,2.24,2.24,0,0,1,1.69.76,3,3,0,0,1,.71,2.12,4.22,4.22,0,0,1-.2,1.36,2.33,2.33,0,0,1-.54.93A2.25,2.25,0,0,1,16,13.11Zm-1.93-1.22a1.29,1.29,0,0,0,2.06,0,2.56,2.56,0,0,0,.4-1.58,2.34,2.34,0,0,0-.41-1.49,1.28,1.28,0,0,0-2,0,2.33,2.33,0,0,0-.41,1.48A2.56,2.56,0,0,0,14.12,11.89Z"
      />
      <path
        className="cls-1"
        d="M19.7,13.15l-1.07-5.6h1l0.76,3.94h0L21.7,7.55h0.54l1.32,3.94h0l0.76-3.94h0.94l-1.07,5.6h-1L22,9.31h0L20.7,13.15h-1Z"
      />
      <path
        className="cls-1"
        d="M26.83,13.15V7.55h3.49V8.42H27.78V9.86H30.1V10.7H27.78v1.58h2.54v0.87H26.83Z"
      />
      <path
        className="cls-1"
        d="M32.08,13.15V7.55h2.23A2,2,0,0,1,35.7,8a1.62,1.62,0,0,1,.52,1.28,1.63,1.63,0,0,1-.34,1.06,1.69,1.69,0,0,1-.91.57l1.21,2.23h-1L34,11H33v2.15H32.08Zm0.94-3h1.22a0.85,0.85,0,0,0,1-.89,0.85,0.85,0,0,0-1-.89H33v1.78Z"
      />
      <path
        className="cls-1"
        d="M37.78,13.15V7.55h3.49V8.42H38.73V9.86H41V10.7H38.73v1.58h2.54v0.87H37.78Z"
      />
      <path
        className="cls-1"
        d="M43,13.15V7.55h1.83a2.44,2.44,0,0,1,.9.17,2.37,2.37,0,0,1,.77.5,2.3,2.3,0,0,1,.55.88,3.58,3.58,0,0,1,.2,1.25,3.88,3.88,0,0,1-.21,1.34,2,2,0,0,1-.57.88,2.38,2.38,0,0,1-.78.45,2.8,2.8,0,0,1-.92.14H43ZM44,12.28H44.8a1.41,1.41,0,0,0,1.09-.42,2.26,2.26,0,0,0,.39-1.51,2.25,2.25,0,0,0-.39-1.45,1.28,1.28,0,0,0-1-.47H44v3.86Z"
      />
      <path
        className="cls-1"
        d="M51.49,13.15V7.55h2.3A1.65,1.65,0,0,1,55,8,1.41,1.41,0,0,1,55.43,9a1.17,1.17,0,0,1-.65,1.08v0a1.6,1.6,0,0,1,.6.48,1.3,1.3,0,0,1,.25.82q0,1.71-2.06,1.71h-2.1Zm0.94-3.3h1.23a0.93,0.93,0,0,0,.62-0.18,0.64,0.64,0,0,0,.22-0.52,0.66,0.66,0,0,0-.22-0.54,0.94,0.94,0,0,0-.62-0.18H52.43V9.85Zm0,2.43h1.4a1.7,1.7,0,0,0,.34-0.06,0.7,0.7,0,0,0,.28-0.14,0.72,0.72,0,0,0,.17-0.25,1,1,0,0,0,.07-0.39,0.66,0.66,0,0,0-.31-0.65,2.16,2.16,0,0,0-1-.16h-1v1.65Z"
      />
      <path
        className="cls-1"
        d="M58.23,13.15V10.79L56.4,7.55h1l1.28,2.38h0L60,7.55h1l-1.83,3.24v2.36H58.23Z"
      />
    </svg>
  );
};
