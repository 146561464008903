import { Loader, Paper, Text, useMantineTheme } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { useNavigate } from "react-router";
import { GetHockeyEventsResponse } from "../api/Descriptors";
import { useHockeyEvents } from "../hooks/useHockeyEvent";
import { formatDate } from "../util/naming";

type HockeyEventCardProps = {
  old?: boolean;
} & GetHockeyEventsResponse["hockey_events"][0];

const HockeyEventCard = (props: HockeyEventCardProps) => {
  const { old } = props;
  const { hovered, ref } = useHover();
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const navigateToDetailedView = (id: number) => {
    navigate(`/events/${id}`);
  };

  return (
    <Paper
      ref={ref}
      shadow="xl"
      mt={"1rem"}
      pt={"1rem"}
      key={props.id}
      onClick={() => navigateToDetailedView(props.id)}
      style={{
        cursor: "pointer",
        ...(hovered
          ? {
            backgroundColor: theme.colors.gray[3],
          }
          : {
            backgroundColor: old ? theme.colors.gray[6] : theme.white,
          }),
      }}
    >
      <Text fz={"lg"}>{formatDate(props.date)}</Text>
      <Text fz={"xl"} tt={"capitalize"} td={"underline"}>
        {props.title}
      </Text>
      <Text fz={"sm"}>{props.type}</Text>
      <Text fz={"md"}>{props.description}</Text>
      <br />
    </Paper>
  );
};

export const HockeyEventList = () => {
  const { useAllHockeyEvents } = useHockeyEvents();
  const { data, isLoading, error } = useAllHockeyEvents();

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <>Error: {error}</>;
  }

  return (
    <div>
      {data?.hockey_events.map((hockeyEvent) => {
        //only display if the event is in the future
        if (new Date(hockeyEvent.date) < new Date()) {
          return null;
        }

        return <HockeyEventCard key={hockeyEvent.id} {...hockeyEvent} />;
      })}
      <hr />
      {data?.hockey_events.map((hockeyEvent) => {
        //only display if the event is in the future
        if (new Date(hockeyEvent.date) >= new Date()) {
          return null;
        }

        return <HockeyEventCard key={hockeyEvent.id} {...hockeyEvent} old />;
      })}
    </div>
  );
};
