import { Button, Flex } from "@mantine/core";
import { Outlet, useNavigate } from "react-router";
import styles from "../equipment.module.css";
import { RiArrowLeftDoubleFill } from "react-icons/ri";

export const EquipmentLayout = () => {
  const navigate = useNavigate();
  return (
    <>
      <Flex>
        <Button
          onClick={() => navigate("/knowledge-base/equipment")}
          mr={"auto"}
          className={styles.equipmentButton}
          leftSection={<RiArrowLeftDoubleFill />}
        >
          {"Felszerelések"}
        </Button>
      </Flex>
      <Outlet />
    </>
  );
};
