import {
  Image,
  Text,
  Flex,
  Anchor,
  Container,
  Stack,
  Title,
  List,
} from "@mantine/core";

export const SticksGuide = () => {
  return (
    <Container size="md" my={20}>
      <Stack p="xl">
        {/* Title */}
        <Title order={1} fw={900} ta="left">
          Ütő vásárlási útmutató
        </Title>

        <Text size="md" ta="justify">
          Bár a kesztyű és az uszony is nagy hatással van egy játékos stílusára,
          mégis az ütő talán a legmeghatározóbb választás, amivel egy új hokis
          szembesül. A játékeszköz mérete, formája, anyaga és kialakítása mind
          komolyan befolyásolják a korongkezelési és lövési tulajdonságokat,
          amik direkt hatással vannak a játékos teljesítményére egyes
          szituációkban.
        </Text>

        <Title order={2} fw={900} ta="left">
          Méret alapján kategorizálás
        </Title>

        <Text size="md" ta="justify">
          Az ütő méretei közül a <b>hossza</b> talán a legmeghatározóbb, mivel a
          szélesség általában ezzel arányos (a modern ütők kialakítása nagyjából
          hasonló), a vastagság/magasság pedig azonos a hossztól függetlenül.
          Hossz szerint 3 kategóriába sorolhatjuk az ütőket:
          <List>
            <List.Item>hosszú - 28-30 cm,</List.Item>
            <List.Item>közepes - 26.5-28 cm,</List.Item>
            <List.Item>rövid - 25-26.5 cm.</List.Item>
          </List>
          Bár kicsinek tűnnek a különbségek, de a víz ellenállása annyival
          nagyobb, hogy akár fél centi különbséget is nagyon érezni.
        </Text>
        <Text size="md" ta="justify">
          A <b>hosszú</b> ütők hátránya, hogy nagyon leredukálják az effektíven
          használható technika megoldásokat. A játékost arra kényszeríti (ami
          nem feltétlenül rossz dolog), hogy a legalapvetőbb -fordulás, lövés,
          szerelés- repertoárt használja, ám ezeket nagyban támogatja. Mivel a
          lövőfelület nagyobb, így ezekkel az ütőkkel nagyobbat is lehet lőni
          (habár a mozdulat maga valamivel lassabb lesz). A szerelés is
          általában effektívebb, a plusz centiméterek miatt könnyebb a korongot
          elérni. Az ütő extra tömege a közelharcnál, ütközésnél jól tud jönni.
        </Text>
        <Text size="md" ta="justify">
          Azoknak a játékosoknak érdemes ilyet választania, akiknek erős keze
          van és preferálják az egyszerű, tiszta és inkább csapatjátékra épülő
          stílust.
        </Text>

        <Stack>
          <Text size="md" ta="justify">
            Példa hosszú ütőkre a{" "}
            <b>
              <Anchor href="https://hydrouwh.com/collections/sticks/products/numbat-270-sticks">
                {"Numbat"}
              </Anchor>
            </b>
            {", a "}
            <b>
              <Anchor href="https://hydrouwh.com/collections/rubberised-sticks/products/soft-tomahawk-ghost">
                {"Tomahawk"}
              </Anchor>
            </b>{" "}
            és a{" "}
            <b>
              <Anchor href="https://hydrouwh.com/collections/sticks/products/batarang-sticks">
                {"Batarang "}
              </Anchor>
            </b>
            . Ezek mind megtalálhatóak a csapatban, tesztelésre érdemes
            kölcsönkérni egy-egy edzésre őket.
          </Text>
          <Flex>
            <Stack ml={"auto"}>
              <Image
                src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/uto_numbat.webp"
                alt="HydroUWH Numbat"
                radius="md"
                w={250}
                mx="auto"
              />
              <Text fs={"italic"} size="sm">
                HydroUWH Numbat
              </Text>
            </Stack>
            <Stack>
              <Image
                src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/uto_batarang.webp"
                alt="HydroUWH Batarang"
                radius="md"
                w={250}
                mx="auto"
              />
              <Text fs={"italic"} size="sm">
                HydroUWH Batarang
              </Text>
            </Stack>
            <Stack mr={"auto"}>
              <Image
                src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/uto_tomahawk.webp"
                alt="HydroUWH Tomahawk"
                radius="md"
                w={250}
                mx="auto"
              />
              <Text fs={"italic"} size="sm">
                HydroUWH Tomahawk
              </Text>
            </Stack>
          </Flex>
        </Stack>
        <Text size="md" ta="justify">
          A <b>rövid</b> ütők előnye, hogy nagyon könnyű kezelni a korongot
          velük, közelharcban nagyon jó esélyeket ad a sikerre. A hátrányuk,
          hogy nehezebb szerelni és lőni velük a kis méret miatt, valamint a
          kisebb tömeg ütközésekkor zavaró lehet. Általában kisebb horoggal is
          rendelkeznek ezek az ütők, hogy a pozitív tulajdonságokat még jobban
          kiemeljék.
        </Text>
        <Text size="md" ta="justify">
          Elsősorban technikás, kisebb méretű játékosoknak ajánlott, azon belül
          is a támadó pozíciókban játszóknak, mivel itt ér többet az ilyen
          ütőkkel társuló "high risk, high reward" játékstílus.
        </Text>
        <Stack>
          <Text size="md" ta="justify">
            Példa jó rövid ütőkre a{" "}
            <b>
              <Anchor href="https://hydrouwh.com/collections/rubberised-sticks/products/soft-shiv">
                {"Shiv"}
              </Anchor>
            </b>
            {" és a "}
            <b>
              <Anchor href="https://bentfishdesign.com/product/carve-252-2011">
                {"Carve "}
              </Anchor>
            </b>
            .
          </Text>
          <Flex direction={"column"}>
            <Stack mx={"auto"}>
              <Image
                src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/uto_shiv.webp"
                alt="HydroUWH Shiv"
                radius="md"
                w={400}
                mx="auto"
              />
              <Text fs={"italic"} size="sm">
                HydroUWH Shiv
              </Text>
            </Stack>
            <Stack mx={"auto"}>
              <Image
                src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/uto_carve.png"
                alt="BentFishDesign Carve 25.2"
                radius="md"
                w={330}
                mx="auto"
              />
              <Text fs={"italic"} size="sm">
                BentFishDesign Carve 25.2
              </Text>
            </Stack>
          </Flex>
        </Stack>
        <Text size="md" ta="justify">
          A <b>közepes</b> ütők egy jó egyensúlyt jelentenek a két véglet
          között, tulajdonságaik is ennek megfelelően a hosszú és a rövid ütők
          tulajdonságait keverik. A legtöbb játékosnak egy ilyen ütő legjobb
          választás, főleg, ha még nem alakult ki saját játékstílusa.
        </Text>
        <Flex direction={"column"}>
          <Text size="md" ta="justify">
            Ezek közül egy típus emelendő ki, amely nagy valószínűséggel a
            leggyakrabban használt modell a világon: a
            <b>
              <Anchor href="https://hydrouwh.com/collections/sticks/products/rocket-260-sticks">
                {" Rocket"}
              </Anchor>
            </b>
            . A népszerűsége nem véletlen, szinte mindent effektíven lehet vele
            csinálni. A nagy kampó remek szerelést biztosít, az ütőfelület
            hossza elég egy jó lövéshez és a nem túl nagy méret lehetőség ad a
            cselezésre.
          </Text>
          <Stack mx={"auto"}>
            <Image
              src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/uto_soft_rocket.webp"
              alt="HydroUWH Rocket"
              radius="md"
              w={400}
            />
            <Text fs={"italic"} size="sm">
              HydroUWH Soft Rocket
            </Text>
          </Stack>
        </Flex>

        <Title order={2} fw={900} ta="left">
          Anyag szerint kategórizálás
        </Title>

        <Text size="md" ta="justify">
          Az utóbbi években a hagyományos műanyag ütők mellett megjelentek az
          úgynevezett "puha" ("soft") ütők is. Ezeknél a kemény műanyag magon
          egy puhább külső borítás van, amely a korongkezelést hivatott
          javítani. A puha ütők előnye, hogy a korongot finomabban lehet
          irányítani, közelharc során "tapad" hozzá a korong kicsit. A
          tapasztalatok alapján a lövés azonban kicsit problémásabb vele,
          nehezebb igazán nagyot lőni. A fentebbi képen egy ilyen "soft"
          borítású Rocket látható.
        </Text>

        <Title order={2} fw={900} ta="left">
          Kivágás az ütőn
        </Title>

        <Flex direction={"column"}>
          <Text size="md" ta="justify">
            Sok típus rendelkezik alternatív modellel, amely annyiban
            különbözik, hogy kivágások vannak az ütő lapján. Ilyen például a{" "}
            <b>
              <Anchor href="https://hydrouwh.com/collections/rubberised-sticks/products/soft-rocket-ghost">
                {" Soft Ghost Rocket, "}
              </Anchor>
            </b>
            amely kialakítása megegyezik a fenti Soft Rocketéval, de egy hálós
            felület van az ütő tollánál a tömör Rocket helyett. Ez jelentősen
            csökkenti az ütő vízellenállását, amikor azt vertikálisan
            használjuk, valamint könnyebbé teszi azt. Ez utóbbi a kis ütők
            hátrányához tud vezetni: kisebb lövés és ütközéskor "üres kéz"
            érzés.
          </Text>
          <Stack mx={"auto"}>
            <Image
              src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/uto_ghost_soft_rocket.webp"
              alt="HydroUWH Ghost Soft Rocket"
              radius="md"
              w={400}
            />
            <Text fs={"italic"} size="sm">
              HydroUWH Ghost Soft Rocket
            </Text>
          </Stack>
        </Flex>

        <Title order={2} fw={900} ta="left">
          Online boltok
        </Title>
        <List>
          <List.Item ta={"justify"}>
            <Anchor href="https://hydrouwh.com">HydroUWH shop</Anchor>:
            Új-Zélandi bázisú üzlet. Rendeléskor figyelembe kell venni az
            áraknál adott esetben a VÁM-ot és ÁFÁt! Általában 2-3 hét alatt
            megérkeznek a termékek.
          </List.Item>
          <List.Item ta={"justify"}>
            <Anchor href="https://uwhstore.more-sport.com/en/">
              More-Sports UWH Store
            </Anchor>
            : Francia üzlet, főleg HydroUWH termékeket árul. Nagy rendelés
            esetén NAGYON lassú a szállítás (6-7 hónap volt legutóbb), de
            egy-egy termék esetén valószínűleg jobb a helyzet.
          </List.Item>
          <List.Item ta={"justify"}>
            <Anchor href="https://shop.gbuwh.co.uk/product-category/sticks/">
              GBUWH Shop
            </Anchor>
            : Brit üzlet. Ritkán rendeltünk innen, alapvetően jó tapasztalatok.
            Brexit óta nem rendeltünk innen, nem tudom pontosan, hogy ez
            mennyiben befolyásolja az árat és a szállítást.
          </List.Item>
          <List.Item ta={"justify"}>
            <Anchor href="https://coreuwhgear.com/en/">Core Gear UWH</Anchor>:
            Személyesen nem ismerem a termékeket, de innen származó ütő és
            kesztyű is található a magyar játékosok körében.
          </List.Item>
        </List>
      </Stack>
    </Container>
  );
};
