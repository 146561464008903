import { Container, Stack, Title, Text, Image, Flex } from "@mantine/core";

export const CapsGuide = () => {
  return (
    <Container size="md" my={20}>
      <Stack p="xl">
        {/* Title */}
        <Title order={1} fw={900} ta="left">
          Sapka vásárlási útmutató
        </Title>

        <Text size="md" ta="justify">
          A sapka a fül védelmére szolgál és még edzés során is kötelező
          tartozék.
        </Text>

        <Text size="md" ta="justify">
          Egy teljesen standard vízilabdából ismert sapkáról van szó, nincs
          semmilyen hokispecifikus része, így bárhol megvehető.
        </Text>

        <Stack>
          <Text size="md" ta="justify">
            Egy dolog, amire potenciálisan megéri figyelni, hogy a fülrésznél
            lévő műanyag védőrészen a lyukak ne legyen túl picik. A pipát igény
            szerint lehet ezekhez is rögzíteni egy gumiszalag segítségével, de
            csak akkor ha elég nagyok a kivágások a műanyag védőkupakon. A
            különbség a lenti két képen látható.
          </Text>
          <Flex mt="md">
            <Image
              src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/sapka_nagy_lyuk.jpeg"
              alt="Nagy lyukú sapka"
              radius="md"
              w={300}
              mx="auto"
            />
            <Image
              src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/sapka_pici_lyuk.jpeg"
              alt="Pici lyukú sapka"
              radius="md"
              w={300}
              mx="auto"
            />
          </Flex>
        </Stack>

        <Text size="md" ta="justify" mt={"md"}>
          Versenyen a sapkának egységesnek kell lennie a csapatot belül,
          valamint színvilágban passzolnia kell a csapat színéhez (fekete
          csapatnak sötét sapka), ám edzésen ennek nincs jelentősége, bármilyen
          sapka megfelel (tehát szabadon választható tetszés szerint a desing).
        </Text>
      </Stack>
    </Container>
  );
};
