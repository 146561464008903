import { useNavigate, useParams } from "react-router-dom";
import { PlayerIdNameMap, TrainingData } from "../api/Descriptors";
import { TrainingClient } from "../api/Trainings";
import { usePlayers } from "../hooks/usePlayers";
import { useTrainings } from "../hooks/useTrainings";
import { Loader, Button, Flex, Table, Center } from "@mantine/core";

type SingleTrainigPlayerInforTableURLParams = {
  trainingId: string;
};

export const SingleTrainingPlayerInfoTable = () => {
  let { trainingId } = useParams<SingleTrainigPlayerInforTableURLParams>();
  const navigate = useNavigate();

  const { useIdNameMap } = usePlayers();
  const { useTraining } = useTrainings();
  const {
    data: idNameMap,
    error: errorINM,
    isLoading: idNameMapLoading,
  } = useIdNameMap();
  const {
    data: trainingData,
    error: errorTraining,
    isLoading: isLoadingTraining,
  } = useTraining(trainingId!);

  const handleEditClick = () => {
    navigate(`/trainings/${trainingId}/score`);
  };

  const handleDeleteClick = async () => {
    if (trainingId === undefined) {
      return;
    }
    let tId = parseInt(trainingId);
    try {
      // TODO: useDeleteTraining hook
      let resp = await new TrainingClient().deleteTraining(tId);
      if (resp.status === "fail") {
        throw new Error(resp.message);
      }
      navigate("/trainings?pageNumber=1&pageSize=10");
    } catch (reason) {
      console.log(reason);
    }
  };

  if (errorTraining || errorINM) {
    return <h2>{"Could not fetch training or id-name map"}</h2>;
  }

  if (isLoadingTraining || idNameMapLoading) {
    return <Loader />;
  }
  return (
    <Flex direction="column">
      <Table
        style={{ margin: "1rem" }}
        withRowBorders
        withTableBorder
        withColumnBorders
      >
        <TrainingPlayerInfoHeader />
        <TrainingBody idNameMap={idNameMap!} trainingData={trainingData!} />
      </Table>
      <Flex
        style={{
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Button
          color="green"
          onClick={handleEditClick}
          style={{
            marginRight: "3rem",
            width: 150,
          }}
        >
          Szerkesztés
        </Button>
        <Button
          color="red"
          onClick={() => handleDeleteClick()}
          style={{
            width: 150,
          }}
        >
          Törlés
        </Button>
      </Flex>
    </Flex>
  );
};

type TrainingBodyProps = {
  trainingData: TrainingData;
  idNameMap: PlayerIdNameMap;
};
const TrainingBody = (props: TrainingBodyProps) => {
  const { trainingData, idNameMap } = props;
  return (
    <Table.Tbody>
      {trainingData.white_team.map((playerId) => {
        const pId = playerId.toString();
        const player = idNameMap[pId];
        const name = `${player.lname} ${player.fname}`;
        const scored = trainingData.white_scorers[playerId]
          ? trainingData.white_scorers[playerId]
          : 0;
        return (
          <TrainingPlayerInfoRow
            color="white"
            id={playerId}
            name={name}
            scored={scored}
            key={pId}
          />
        );
      })}
      {trainingData.black_team.map((playerId) => {
        const pId = playerId.toString();
        const player = idNameMap[pId];
        const name = `${player.lname} ${player.fname}`;
        const scored = trainingData.black_scorers[playerId]
          ? trainingData.black_scorers[playerId]
          : 0;
        return (
          <TrainingPlayerInfoRow
            color="black"
            id={playerId}
            name={name}
            scored={scored}
            key={pId}
          />
        );
      })}
      {trainingData.swimming_players.map((playerId) => {
        const pId = playerId.toString();
        const player = idNameMap[pId];
        const name = `${player.lname} ${player.fname}`;
        return (
          <TrainingPlayerInfoRow
            color="blue"
            id={playerId}
            name={name}
            scored={0}
            key={pId}
          />
        );
      })}
    </Table.Tbody>
  );
};

const TrainingPlayerInfoHeader = () => {
  return (
    <Table.Thead>
      <Table.Tr>
        <Table.Th>
          <Center>Azonosító</Center>
        </Table.Th>
        <Table.Th>
          <Center>Név</Center>
        </Table.Th>
        <Table.Th>
          <Center>Gól #</Center>
        </Table.Th>
      </Table.Tr>
    </Table.Thead>
  );
};

type TrainingPlayerInfoRowProps = {
  id: number;
  name: string;
  color: "black" | "white" | "blue";
  scored: number;
};

const TrainingPlayerInfoRow = (props: TrainingPlayerInfoRowProps) => {
  const { color, id, name, scored } = props;
  const myblue = "var(--mantine-color-myblue-3)";
  let c = color === "blue" ? myblue : color;

  const textColor = () => {
    switch (color) {
      case "black":
      case "blue":
        return "white";
      case "white":
      default:
        return "black";
    }
  };

  return (
    <Table.Tr style={{ background: c, color: textColor() }}>
      <Table.Td>{id}</Table.Td>
      <Table.Td>{name}</Table.Td>
      <Table.Td>{scored}</Table.Td>
    </Table.Tr>
  );
};
