import {
  Text,
  Image,
  Container,
  Stack,
  Title,
  Flex,
  Anchor,
} from "@mantine/core";

export const FinsGuide = () => {
  return (
    <Container size="md" my={20}>
      <Stack p="xl">
        {/* Title */}
        <Title order={1} fw={900} ta="left">
          Uszony vásárlási útmutató
        </Title>

        <Stack p={"md"}>
          <Text size="md" ta="justify">
            A modern hokit a robbanékonyság, rövid sprintek és gyors fordulások
            jellemzik. Ezzel összhangban a használt uszonyok is megváltoztak:
            míg régen a hosszú, nagy felületű Mareq Quattro volt a standard, ma
            már a kisebb uszonyok preferáltak.
          </Text>
          <Image
            src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/mares_quattro.jpg"
            alt="Mares Avanti Quattro Power"
            radius="md"
            w={200}
            mx="auto"
          />
          <Text fs={"italic"} size="sm">
            Mares Avanti Quattro Power
          </Text>
        </Stack>

        <Stack p={"md"}>
          <Text size="md" ta="justify">
            2006 környékén kezdtek el megjelenni a piacon a vízalatti sportokra
            tervezett kompozit uszonyok, amelyek jó időre átvették a vezető
            szerepet a hagyományos gumi uszonyoktól, mint a legjobb választás
            hokihoz. Ezek jelenleg is megtalálhatóak a piacon (Breier,
            Leaderfins, Rocket fins, stb.), ám az áruk miatt egyre kevesebben
            döntenek mellettük, tekintve, hogy van olcsóbb alternatíva közel
            hasonló teljesítménnyel.
          </Text>
          <Image
            src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/breier.jpg"
            alt="Breier karbon uszony"
            radius="md"
            w={200}
            mx="auto"
          />
          <Text fs={"italic"} size="sm">
            Breier karbon uszony
          </Text>
        </Stack>

        <Stack p={"md"}>
          <Text size="md" ta="justify">
            Két magyar fejlesztésű uszony dominálja a piacot az utóbbi pár
            évben: a
            <b>
              <Anchor href="https://www.murenafin.org/">{" Muréna "}</Anchor>
            </b>
            és a
            <b>
              <Anchor href="https://www.najadefins.org/product/fins">
                {" Najade"}
              </Anchor>
            </b>
            . Mindkettő hasonló kialakítású, kis méretű, teljesen gumiból
            készült uszony. Sok remek tulajdonsággal rendelkeznek víz alatti
            hokihoz: jó gyorsulást tesznek lehetővé, kis méretük miatt könnyű
            velük a víz alatt manőverezni és a gumi anyag miatt nem
            veszélyeztetik a csapattársak bőrét sem. A méret további előnye,
            hogy szállítani is könnyebb, kisebb helyen is elférnek.
          </Text>
          <Flex direction={"row"}>
            <Stack ml={"auto"}>
              <Image
                src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/najade.jpg"
                alt="Najade"
                radius="md"
                w={200}
              />
            </Stack>
            <Stack mr={"auto"} ml={"5rem"}>
              <Image
                src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/murena.webp"
                alt="Muréna"
                radius="md"
                w={200}
              />
            </Stack>
          </Flex>
        </Stack>
        <Text size="md" ta="justify">
          Mindkét uszonyt érdemes kipróbálni az edzésen zoknival és anélkül is.
          Néhány játékosnak elsőre kényelmetlen tud lenni mindkét modell, de a
          megfelelő kiegészítők (neoprén zokni, sima zokni, szigszalag, stb.)
          minden játékosunk eddig meg tudta találni a komfortos megoldást.
        </Text>
        <Text size="md" ta="justify">
          Rendelés esetén is keressétek a csapatot, előfordulhat, hogy tömeges
          rendelés esetén kapunk kedvezményt.
        </Text>
      </Stack>
    </Container>
  );
};
