import {
  Text,
  Grid,
  Image,
  Container,
  Stack,
  Title,
  Flex,
} from "@mantine/core";

export const SnorkelGuide = () => {
  return (
    <Container size="md" my={20}>
      <Stack p="xl">
        {/* Title */}
        <Title order={1} fw={900} ta="left">
          Vízihoki pipa vásárlási útmutató
        </Title>

        <Text size="md" ta="justify">
          A kényelmes pipa nagyon egyénfüggő. Alapvetően minél szélesebb és
          rövidebb, annál jobb, hiszen ekkor jelenti levegővételkor a legkisebb
          ellenállást, valamint víz alatti sprinteléskor kisebb az ellenállása.
          A problémát az jelenti, hogy ezek a tulajdonságok -rövidség és nagy
          átmérő- bizonyos hátrányokkal is járnak, elsősorban a kezdőbb
          játékosok számára.
        </Text>

        <Text size="md" ta="justify">
          <b>Minél rövidebb</b> a pipa, annál könnyebben ér bele a vízbe a vége,
          miközben a felszínen követi a meccset a játékos. Emiatt nagyon
          "precízen" kell időzíteni a légvételt, különben könnyen vizet nyelhet
          az ember.
        </Text>

        <Text size="md" ta="justify">
          <b>Minél szélesebb</b> a pipa, annál nehezebb kiüríteni a vizet belőle
          a felszínre jövetel után. Egy elégtelen ürítés megintcsak víz
          nyeléshez vezethet.
        </Text>

        <Text size="md" ta="justify">
          Ezek alapján tehát egy kompromisszumot kell kötni és megítélni, hogy
          mi a lehető legrövidebb és legszélesebb pipa, amit a játékos még
          magabiztosan használni tud.
        </Text>

        <Title order={2} fw={900} mt="1rem" ta="left">
          Pipa anyaga
        </Title>

        <Stack>
          <Text size="md" ta="justify">
            A pipa anyaga legyen mindenképp rugalmas, gumis anyagú. A cél, hogy
            minél jobban kövesse a fej alakját, ezzel is csökkentve a
            vízellenállást úszáskor. A kemény műanyagból készült pipák
            veszélyesek tudnak lenni a többi játékosra, könnyen megkarcolják a
            bőrt ütközéskor.
            <br />
            <b>Fontos: ne legyen gégecsöves</b> a pipa és{" "}
            <b>ne legyen rajta hullámtörő</b>!
          </Text>
          <Image
            src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/ilyet-ne.webp"
            alt="Elrettentő példa"
            radius="md"
            w={300}
            mx="auto"
          />
          <Text fs={"italic"} size="sm">
            Az anti-hoki pipa: hullámtörő, merev részek, gégecső, szelep
          </Text>
        </Stack>

        <Title order={2} fw={900} mt="1rem" ta="left">
          Szájrész: szelepes vagy szelep nélküli?
        </Title>

        <Flex direction="column">
          <Text size="md" ta="justify">
            A pipa szájrésze két különböző típusban kapható: szelepes és szelep
            nélküli. Bár mindkettővel többen játszanak a csapatban, mégis{" "}
            <b>új vásárlás esetén a szelep nélküli</b> ajánlott. Ennek egyik
            oka, hogy néhány versenyen tiltják a szelepes pipát, illetve a
            modern fogvédők a szelep nélküli pipákra sokkal könnyebben
            felmennek.
          </Text>

          <Grid>
            <Grid.Col span={6}>
              <Stack>
                <Image
                  src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/szelepes.webp"
                  alt="Szelepes pipa"
                  radius="md"
                  w={300}
                  mx="auto"
                />
                <Text fs="italic" size="sm">
                  Szelepes
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={6}>
              <Stack>
                <Image
                  src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/rugalmas.jpg"
                  alt="Szelep nélküli"
                  radius="md"
                  w={300}
                  mx="auto"
                />
                <Text fs="italic" size="sm">
                  Szelep nélküli
                </Text>
              </Stack>
            </Grid.Col>
          </Grid>
        </Flex>
      </Stack>
    </Container>
  );
};
