interface FinIconProps extends React.ComponentPropsWithoutRef<"svg"> {
  size?: number | string;
}

export const FinIcon: React.FC<FinIconProps> = ({
  size = 150,
  style,
  ...others
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150 150"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      style={{ width: size, height: size, ...style }}
      {...others}
    >
      <g id="uszony">
        <path d="M54.23,14.06c.23,1.53-.18,3.07-.16,4.6s.22,3.21,.41,4.79c.37,3.17,.88,6.32,1.53,9.44,1.31,6.34,3.15,12.55,5.18,18.69,3.99,12.05,8.81,23.82,12.2,36.07,3.27,11.82,5.33,24.25,3.73,36.52-.7,5.38-1.91,11.33-5.05,15.87-1.53,2.21-3.65,3.89-6.23,4.7-2.78,.87-5.71,.92-8.6,.96-6.54,.1-13.08,.19-19.62,.28-5.61,.08-11.39,.48-16.96-.44-5.14-.85-9.93-3.03-12.87-7.5s-3.84-9.55-4.27-14.6c-2.17-25.81,7.52-50.53,14.96-74.74,1.89-6.15,3.66-12.33,5.1-18.6,.7-3.07,1.4-6.17,1.77-9.3s.38-6.28,.52-9.42c.22-4.93,5.82-7.59,10.02-8.42,1.34-.27,2.71-.35,4.07-.26,.79,.05,1.55,.19,2.33,.34,.72,.14,1.44,.1,2.14,.21,.67,.1,1.13,.41,1.7,.73s1.22,.47,1.79,.78c1.07,.58,2.21,1.38,3.13,2.17,.86,.74,1.78,1.71,2.35,2.7,.64,1.11,.75,2.87,.81,4.11,.08,1.6,2.58,1.61,2.5,0-.07-1.43-.26-2.84-.66-4.24-.35-1.22-1.32-2.31-2.13-3.27s-1.74-1.63-2.73-2.29c-.56-.37-1.09-.8-1.66-1.15-.67-.41-1.44-.6-2.12-.97-1.16-.65-2.1-.97-3.43-1.05-1.66-.1-3.25-.6-4.94-.6-2.53-.01-5.06,.52-7.4,1.48-2.2,.9-4.34,2.17-5.89,3.99-.78,.92-1.35,1.92-1.82,3.04-.62,1.47-.56,3.04-.61,4.62-.12,3.46-.25,6.89-.9,10.3s-1.37,6.55-2.18,9.79C13.6,59.62,1.86,84.87,.79,112.26c-.24,6.09-.09,12.4,1.3,18.36,1.19,5.1,3.54,10.02,7.78,13.26s10.11,4.36,15.55,4.62c6.34,.3,12.72-.03,19.06-.12l10.3-.15c3.08-.04,6.19,0,9.24-.5,5.84-.97,9.88-4.77,12.2-10.09s3.4-11.61,3.8-17.54c.43-6.52-.09-13.06-1.16-19.5-4.42-26.66-18.94-50.65-21.93-77.62-.17-1.57-.39-3.19-.34-4.77s.32-3.16,.07-4.81-2.65-.91-2.41,.66Z" />
        <path d="M48.43,22.89c-1.3,1.59-3.01,3.25-5.2,3.43-2.06,.17-4.15,.77-6.19,.19s-4.2-.83-5.68-2.31c-.79-.79-1.47-1.77-1.88-2.82s-.5-2.35-.61-3.43,.03-2.1,.08-3.19c.02-.51-.11-1.01-.08-1.52,.05-.7,.42-1.45,.78-2.04,.75-1.21,1.8-1.84,2.93-2.65,.49-.35,.9-.75,1.48-.94s1.23-.22,1.82-.36c.46-.1,.92-.19,1.38-.29,.85-.19,1.32-.05,2.16,.05,.46,.05,.9,.04,1.36,.04,.71,0,1.29,.13,1.97,.28,2.04,.44,4.68-.02,6.01,1.97,.52,.78,1,1.61,1.25,2.53,.29,1.06,.44,2.26,.52,3.35,.1,1.27-.27,2.49-.46,3.73-.24,1.52-.94,2.76-1.74,4.05-.34,.55,.52,1.05,.86,.5,.73-1.18,1.46-2.34,1.78-3.72s.49-2.73,.54-4.11c.04-1.02-.13-2.11-.38-3.09-.28-1.1-.39-2.13-1.07-3.05-.62-.83-1.11-1.65-2.06-2.21-1.03-.61-2.27-.63-3.42-.75s-2.25-.48-3.38-.5c-1.44-.03-2.81-.39-4.22-.05-1.13,.28-2.43,.28-3.5,.71-.53,.21-.97,.65-1.43,.98-.7,.5-1.49,.91-2.12,1.5-.81,.77-1.44,1.82-1.82,2.86-.18,.5-.29,.93-.28,1.45,.02,.86,0,1.64-.07,2.5-.09,1.2,.12,2.36,.32,3.54,.1,.61,.04,1.16,.25,1.74,.25,.72,.63,1.38,1.02,2.03,.54,.89,1.29,1.81,2.15,2.41,.37,.26,.76,.32,1.17,.47,.64,.24,1.23,.63,1.91,.77,1.39,.29,2.87,.8,4.3,.86,1.29,.06,2.63-.41,3.92-.51,2.73-.21,4.62-1.7,6.29-3.73,.4-.49-.3-1.21-.71-.71Z" />
        <path d="M54.88,51c-.06,1.19,.47,2.32,.51,3.5,.04,1.29,.19,2.55,.26,3.83s.27,2.6,.54,3.87c.31,1.43,.3,2.9,.66,4.32,.65,2.54,.93,5.19,1.4,7.76,.51,2.76,1.29,5.47,1.74,8.25s1.07,5.36,1.41,8.07c.18,1.41,.34,2.82,.52,4.23,.09,.74,.08,1.38,.09,2.12,0,.67,.21,1.31,.26,1.97,.11,1.53,.01,3.1,.01,4.64s.18,3.07,.25,4.62c.13,2.88,.7,5.66,.56,8.55-.08,1.61-.3,3.18-.3,4.79,0,1.75,.04,3.51,0,5.26-.04,1.65-.59,3.2-.78,4.83-.04,.31-.01,4.54,.36,4.47-.59,.12-.34,1.03,.25,.91l.21-.04c.14-.03,.31-.18,.33-.33,.18-1.13-.21-2.29-.24-3.43-.05-1.56,.36-2.99,.63-4.51,.51-2.93,.02-5.97,.2-8.93,.09-1.45,.32-2.88,.34-4.34s-.22-2.96-.34-4.45c-.24-3.06-.53-6.06-.53-9.14,0-2.87-.15-5.74-.53-8.58-.33-2.51-.55-5.08-1.23-7.52-.39-1.41-.43-2.9-.73-4.33s-.61-2.88-.94-4.31c-.29-1.26-.56-2.51-.81-3.79-.11-.57-.06-1.15-.17-1.72-.18-.94-.46-1.81-.58-2.76-.18-1.53-.66-2.97-.8-4.5s-.43-3.04-.6-4.56c-.16-1.44-.49-2.9-.5-4.36,0-.71,.03-1.38-.12-2.08-.16-.75-.43-1.51-.39-2.29,.03-.61-.91-.61-.94,0h0Z" />
        <path d="M39.31,88.28c-.1,1.11-.25,2.13-.26,3.25,0,1.35,0,2.65-.17,3.99-.26,2.04-.29,4.31-.15,6.37,.08,1.08,.29,2.15,.32,3.24,.03,1.25,0,2.51,0,3.77,0,2.46,.07,4.9-.19,7.35-.11,1.06-.02,2.11-.03,3.17-.01,1.15-.15,2.29-.06,3.44,.16,2.02,.58,3.95,.49,5.99-.04,.97,.11,1.82,.26,2.77s.16,1.95,.3,2.92c.24,1.7,.4,3.71,1.08,5.31,.24,.56,1.05,.08,.81-.48-.25-.6-.27-1.18-.39-1.8-.1-.54-.3-1.03-.31-1.58-.02-.81-.23-1.56-.24-2.37-.02-.92-.27-1.83-.41-2.74-.16-1.06-.12-2-.1-3.06,.04-1.94-.56-3.85-.58-5.79-.02-2.23,.03-4.5,.26-6.71,.25-2.34,.05-4.78,.05-7.13,0-1.15,.05-2.31,0-3.45-.05-1.02-.27-2.03-.32-3.05-.12-2.25,.03-4.56,.26-6.79,.12-1.11,.07-2.23,.07-3.34s.16-2.15,.26-3.25c.05-.6-.89-.6-.94,0h0Z" />
        <path d="M23.74,44.36c.04,1.3-.14,2.55-.26,3.84-.14,1.56-.26,3.12-.54,4.66-.54,2.93-1.22,5.81-1.42,8.8-.42,6.39-1.98,12.62-2.81,18.95-.18,1.4-.2,2.8-.41,4.19-.24,1.62-.36,3.27-.61,4.89-.47,3.07-.89,6.14-1.08,9.23s-.78,5.95-.57,8.99,.51,6.25,.55,9.37c0,.9,.26,1.73,.29,2.62,.02,.72-.12,1.43,.03,2.14,.18,.86,.35,1.53,.43,2.42,.06,.71,.26,1.37,.29,2.08,.07,1.54,.48,3.1,.83,4.6,.19,.84,.47,1.63,.78,2.43s.8,1.51,.95,2.31c.11,.6,1.02,.34,.91-.25-.25-1.35-1.15-2.6-1.53-3.94-.42-1.49-.88-3.24-.99-4.79-.09-1.24-.13-2.48-.32-3.71-.09-.58-.39-1.1-.46-1.69-.08-.71,.08-1.42,0-2.14-.11-.96-.27-1.84-.25-2.81,.02-.71,.06-1.41-.07-2.11-.3-1.59-.05-3.16-.26-4.75-.39-2.99-.18-5.86,.13-8.85,.35-3.35,.61-6.7,1.12-10.04,.22-1.44,.39-2.88,.55-4.33,.15-1.34,.46-2.68,.58-4.01,.08-.82,.01-1.59,.16-2.42s.34-1.65,.47-2.48c.25-1.62,.32-3.25,.65-4.86,.29-1.42,.6-2.83,.84-4.26,.27-1.6,.34-3.24,.57-4.84,.27-1.85,.32-3.7,.62-5.54,.24-1.45,.66-2.86,.85-4.31,.23-1.72,.44-3.4,.63-5.12,.16-1.45,.36-2.81,.31-4.28-.02-.61-.96-.61-.94,0h0Z" />
        <path d="M26.03,68.11c0,.21-.2,.58-.24,.79-.08,.43-.03,.94-.03,1.38,0,1.05,.14,1.97,.44,2.98,.5,1.72,.79,3.41,2.16,4.67,.73,.67,1.25,1.52,2.03,2.13,.7,.55,1.5,.94,2.32,1.26,1.77,.7,3.95,1.26,5.88,1.11,.92-.07,1.69-.5,2.62-.56s1.68-.19,2.51-.57c.89-.41,1.9-.55,2.76-1.01s1.52-1.16,2.22-1.75c1.53-1.28,3.25-2.57,4.17-4.4s.92-3.96,.92-6.02c0-.61-.95-.61-.94,0,0,2.03,.05,4.06-.97,5.89-.86,1.54-2.48,2.75-3.84,3.86-.65,.53-1.28,1.23-2.03,1.6s-1.64,.46-2.42,.85c-.9,.45-1.74,.58-2.73,.58-.77,0-1.29,.4-2.03,.54-1.59,.3-3.55-.25-5.06-.74-.97-.32-1.93-.73-2.74-1.36-.69-.53-1.13-1.26-1.75-1.86-.27-.27-.63-.47-.85-.8-.18-.27-.25-.61-.4-.9-.46-.87-.64-1.83-.92-2.78s-.41-1.78-.4-2.72c0-.77,.26-1.45,.27-2.17,0-.61-.94-.61-.94,0h0Z" />
        <path d="M28.91,28.34c2.11,3.23,5.87,4.26,9.49,4.71,1.58,.19,3.42,.2,4.93-.34,.88-.32,1.72-.45,2.48-1.02,.71-.53,1.58-.73,2.22-1.38,.43-.43-.24-1.1-.67-.67-.54,.55-1.29,.68-1.91,1.11-.75,.53-1.44,.68-2.29,1-1.36,.52-3.01,.5-4.44,.39-3.32-.26-7.08-1.35-9-4.28-.33-.51-1.15-.03-.81,.48h0Z" />
        <path d="M75.89,130.34c4.02,5.78,9.4,11.47,16.69,12.6,8.45,1.32,16.28-3.36,23.34-7.35,3.98-2.25,7.97-4.51,11.95-6.76,3.78-2.14,7.63-4.17,11.32-6.46,3.05-1.89,5.97-4.25,7.48-7.59s1.6-7.28,1.07-10.98c-2.18-14.98-11.48-28.33-21.48-39.23-11.33-12.35-24.74-22.5-36.59-34.32-3.04-3.04-5.98-6.18-8.73-9.48s-5.6-6.69-7.53-10.43c-.44-.86-.76-1.79-1.2-2.65-.39-.76-.89-1.45-1.3-2.19s-1.12-1.27-1.64-1.92-1.09-1.22-1.88-1.59c-1.47-.7-3-1.3-4.62-1.51-.84-.11-1.71-.17-2.56-.25-1.05-.1-2.08,.11-3.13,.1-1.78-.02-3.17,.83-4.83,1.35-1.53,.48-.88,2.89,.66,2.41,.77-.24,1.54-.46,2.26-.83,.77-.4,1.4-.41,2.25-.4,1.03,0,1.98-.21,3.02-.09,.82,.09,1.68,.11,2.49,.28,1.39,.28,3.34,.84,4.27,1.95,1.01,1.21,2.01,2.39,2.75,3.8,.92,1.73,1.61,3.45,2.7,5.1s2.25,3.26,3.45,4.83c2.39,3.13,4.94,6.12,7.62,9.01,10.99,11.82,23.87,21.64,35.32,32.99,10.09,10,19.47,21.44,24.09,35.07,2.15,6.35,4.49,15.02-.49,20.66-2.44,2.76-5.84,4.47-8.99,6.25-3.7,2.09-7.4,4.19-11.1,6.28s-7.44,4.25-11.18,6.32c-3.54,1.96-7.2,3.83-11.16,4.8-3.65,.89-7.48,.83-10.96-.69-3.15-1.38-5.79-3.68-8.05-6.24-1.14-1.29-2.18-2.66-3.16-4.08-.91-1.31-3.08-.06-2.16,1.26h0Z" />
        <path d="M59.57,28.12c1.23-.32,2.59-.5,3.63-1.29,.47-.36,.87-.82,1.36-1.16,.6-.42,1.25-.76,1.84-1.19,1.88-1.37,2.78-3.8,3.08-6.02,.38-2.83-.5-5.79-1.87-8.26-.3-.54-.72-1.01-1.04-1.54-.3-.49-.77-.93-1.15-1.37-.36-.41-.86-.94-1.3-1.25-.36-.25-.8-.36-1.18-.58-1.11-.64-2.68-.83-3.89-.35-.68,.27-1.33,.59-1.99,.9-.78,.36-1.68,.44-2.47,.76-.59,.24-.33,1.2,.27,.96,1.28-.51,2.62-.79,3.89-1.33,.96-.41,1.64-.69,2.74-.43,.63,.15,1.09,.51,1.67,.76,1.07,.46,2.05,1.71,2.68,2.68,.37,.58,.81,1.08,1.13,1.7,.39,.76,.66,1.48,.9,2.3,.26,.87,.51,1.62,.62,2.54s.1,1.82-.06,2.77c-.23,1.37-.64,2.48-1.45,3.61-.94,1.32-2.27,1.94-3.49,2.94-.68,.56-1.25,1.04-2.1,1.34-.68,.24-1.38,.36-2.07,.54-.62,.16-.36,1.13,.27,.96h0Z" />
        <path d="M87.67,39.89c.52,1.07,1.52,1.81,2.12,2.82,.72,1.21,1.45,2.39,2.23,3.54,.35,.51,.76,.9,1.09,1.44s.68,.92,1.07,1.39c1,1.17,1.74,2.5,2.79,3.62,.96,1.02,1.69,2.22,2.64,3.25,.82,.89,1.54,1.92,2.29,2.89,1.76,2.27,3.87,4.24,5.59,6.54,1.61,2.14,3.45,4.05,4.99,6.24,.79,1.12,1.6,2.23,2.4,3.35,.42,.59,.74,1.18,1.09,1.82,.39,.7,.97,1.26,1.38,1.95,1.58,2.68,3.04,5.41,4.7,8.05,1.46,2.34,3.19,4.4,4.41,6.9,.76,1.56,1.38,3.14,2.21,4.67s1.75,3.02,2.5,4.58c.71,1.47,.91,3.13,1.6,4.6,.11,.24,2.05,3.6,2.3,3.39-.46,.39,.21,1.06,.67,.67l.16-.14c.12-.1,.17-.31,.12-.46-.32-.96-1.14-1.68-1.65-2.54-.83-1.4-1.24-2.82-1.74-4.36-.95-2.92-2.91-5.4-4.19-8.17-.58-1.26-1.05-2.55-1.69-3.79-.69-1.33-1.59-2.49-2.41-3.74-1.68-2.56-3.38-5.04-4.85-7.73s-2.96-5.12-4.71-7.49c-1.5-2.03-2.9-4.18-4.66-5.98-1.03-1.05-1.79-2.35-2.74-3.47s-1.98-2.35-3.01-3.47c-.84-.91-1.63-1.84-2.41-2.8-.32-.39-.51-.81-.8-1.21-.52-.72-1.23-1.29-1.76-2.01-1.91-2.57-4.01-4.98-5.81-7.61-.83-1.21-1.84-2.32-2.56-3.62-.34-.61-.64-1.22-1.1-1.76-.5-.59-1.1-1.12-1.44-1.83-.26-.55-1.08-.07-.81,.48h0Z" />
        <path d="M91.91,80.07c.45,1.02,.8,1.99,1.34,2.97,.65,1.18,1.27,2.34,1.76,3.59,.76,1.93,1.84,3.96,2.96,5.7,.58,.9,1.27,1.71,1.8,2.64,.63,1.09,1.2,2.2,1.81,3.3,1.21,2.2,2.45,4.35,3.42,6.67,.39,.93,.97,1.77,1.44,2.66,.54,1.01,.97,2.08,1.6,3.04,1.11,1.68,2.39,3.17,3.29,4.98,.44,.88,1,1.55,1.57,2.34,.22,.3,.45,.58,.68,.87,.36,.46,.57,.97,.9,1.44,.26,.36,.63,.6,.87,.98,.21,.33,.31,.62,.56,.92,.64,.75,1.27,1.65,2.05,2.26,.47,.37,1.14-.29,.67-.67-.75-.59-1.44-1.51-2.05-2.26-.27-.33-.38-.74-.64-1.08-.36-.46-.7-.82-.99-1.34-.87-1.52-2.18-2.68-2.97-4.29-.89-1.83-2.3-3.27-3.35-5-1.19-1.96-2.29-4.14-3.15-6.26s-2.2-4.15-3.31-6.18c-.59-1.08-1.14-2.19-1.78-3.24-.52-.85-1.18-1.61-1.71-2.46-1.18-1.9-2.23-4.02-3.05-6.09-.41-1.05-1.02-2.03-1.57-3.02s-.9-1.96-1.34-2.97c-.24-.56-1.06-.08-.81,.48h0Z" />
        <path d="M82.24,110.29c5.37,6.8,9.36,14.77,15.75,20.73,.44,.41,1.11-.25,.67-.67-6.39-5.96-10.38-13.93-15.75-20.73-.38-.48-1.04,.2-.67,.67h0Z" />
        <path d="M70.58,68.75c.1,.18,.1,.61,.17,.81,.14,.42,.43,.83,.64,1.22,.5,.92,1.07,1.65,1.81,2.39,1.14,1.14,2.19,2.55,3.83,3,1.06,.29,2.1,.79,3.19,.96,.99,.15,2.01,.07,3-.08,1.79-.28,3.88-.83,5.39-1.89,.26-.18,.47-.38,.68-.63,.41-.48,.68-.71,1.22-1.03,.77-.46,1.38-.98,1.92-1.71s1.19-1.25,1.73-1.94c.63-.79,.89-1.85,1.27-2.76,.75-1.84,1.63-3.83,1.59-5.85-.04-2.16-1.08-4.01-2.1-5.85-.29-.53-1.11-.06-.81,.48,1,1.82,2.06,3.65,1.96,5.8-.08,1.81-.96,3.69-1.63,5.35-.35,.86-.58,1.82-1.25,2.5-.41,.41-.83,.75-1.18,1.22-.66,.87-1.15,1.51-2.13,2.02-.71,.37-.93,.84-1.48,1.37-1.13,1.12-3.29,1.59-4.8,1.89-1.14,.23-2.42,.37-3.58,.11-.83-.19-1.6-.59-2.42-.82-1.6-.46-2.58-1.66-3.72-2.81-.67-.67-1.21-1.37-1.66-2.2-.36-.67-.46-1.4-.81-2.03-.29-.53-1.1-.06-.81,.48h0Z" />
        <path d="M61.66,33.09c4.23-1.39,8.45-3.65,10.06-8.07,.21-.57-.7-.82-.91-.25-1.49,4.08-5.52,6.14-9.4,7.41-.57,.19-.33,1.1,.25,.91h0Z" />
      </g>
    </svg>
  );
};
