import {
  Card,
  Divider,
  SimpleGrid,
  Group,
  Loader,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { AiOutlineFire } from "react-icons/ai";
import { GoTrophy } from "react-icons/go";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { PlayerInfo } from "../../api/Descriptors";
import { usePlayers } from "../../hooks/usePlayers";

const getName = (playerId: number, players: PlayerInfo[]) => {
  const player = players.find((p) => p.id === playerId);
  return player?.lname + " " + player?.fname;
};

const isActive = (playerId: number, players: PlayerInfo[]) => {
  const player = players.find((p) => p.id === playerId);
  return player?.active;
};

export const PlayerTrainingStreak = () => {
  const theme = useMantineTheme();
  const yellow = "white";
  const blue = theme.colors.myblue[5];
  const { usePlayerTrainingStreak, useAllPlayers } = usePlayers();
  const {
    data: streakData,
    isLoading: isLoadingS,
    error: errorS,
  } = usePlayerTrainingStreak();
  const {
    data: playersData,
    isLoading: isLoadingP,
    error: errorP,
  } = useAllPlayers();

  const streaks = streakData?.data || {};
  const players = playersData || [];

  if (isLoadingS || isLoadingP) {
    return <Loader />;
  }
  if (errorS || errorP) {
    return <h1>Nem sikerült lekérni a adatokat</h1>;
  }

  return (
    <>
      <SimpleGrid
        cols={{
          base: 1,
          lg: 3,
          xl: 3,
          md: 1,
          sm: 1,
          xs: 1,
        }}
        spacing={"xl"}
      >
        <Card shadow="xl" radius={"md"} bg={blue}>
          <Group justify="space-between">
            <GoTrophy size={40} color={yellow} />
            <Text c={yellow} fw={700}>
              Leghosszabb
            </Text>
            <GoTrophy size={40} color={yellow} />
          </Group>
          <Divider my={"md"} c={"blue"} />
          {Object.entries(streaks)
            .filter(([playerId]) => isActive(parseInt(playerId), players))
            .sort((a, b) => b[1].longest_streak - a[1].longest_streak)
            .slice(0, 10)
            .map(([playerId, streak]) => (
              <>
                <Group
                  justify="space-between"
                  m={5}
                  key={"longest" + playerId.toString()}
                >
                  <Text fw={500} c={yellow}>
                    {getName(parseInt(playerId), players)}
                  </Text>
                  <Text fw={500} c={yellow}>
                    {streak.longest_streak}
                  </Text>
                </Group>
              </>
            ))}
        </Card>
        <Card shadow="xl" radius={"md"} bg={blue}>
          <Group justify="space-between">
            <MdOutlineCalendarMonth size={40} color={yellow} />
            <Text c={yellow} fw={700}>
              Idei
            </Text>
            <MdOutlineCalendarMonth size={40} color={yellow} />
          </Group>
          <Divider my={"md"} c={"blue"} />
          {Object.entries(streaks)
            .filter(([playerId]) => isActive(parseInt(playerId), players))
            .sort(
              (a, b) =>
                b[1].current_year_longest_streak -
                a[1].current_year_longest_streak
            )
            .slice(0, 10)
            .map(([playerId, streak]) => (
              <>
                <Group
                  justify="space-between"
                  m={5}
                  key={"current_year_longest" + playerId.toString()}
                >
                  <Text fw={500} c={yellow}>
                    {getName(parseInt(playerId), players)}
                  </Text>
                  <Text fw={500} c={yellow}>
                    {streak.current_year_longest_streak}
                  </Text>
                </Group>
              </>
            ))}
        </Card>
        <Card shadow="xl" radius={"md"} bg={blue}>
          <Group justify="space-between">
            <AiOutlineFire size={40} color={yellow} />
            <Text fw={700} c={yellow}>
              Jelenlegi
            </Text>
            <AiOutlineFire size={40} color={yellow} />
          </Group>
          <Divider my={"md"} c={"blue"} />
          {Object.entries(streaks)
            .filter(([playerId]) => isActive(parseInt(playerId), players))
            .sort((a, b) => b[1].current_streak - a[1].current_streak)
            .slice(0, 10)
            .map(([playerId, streak]) => (
              <>
                <Group
                  justify="space-between"
                  m={5}
                  key={"current" + playerId.toString()}
                >
                  <Text fw={500} c={yellow}>
                    {getName(parseInt(playerId), players)}
                  </Text>
                  <Text fw={500} c={yellow}>
                    {streak.current_streak}
                  </Text>
                </Group>
              </>
            ))}
        </Card>
      </SimpleGrid>
    </>
  );
};
